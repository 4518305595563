.c-footer-layout {
  background-color: var(--color-graphite);
  color: var(--color-white);
  line-height: 1.5em;
  padding: 24px;

  &__title {
    font-weight: 500;
  }

  &__block-1 {
    width: 100%;
    display: grid;
    grid-template-columns: 280px 1fr 320px;
    align-items: center;
    grid-gap: 24px;
    padding: 0px 0px 24px;

    &__page {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 24px;

      .c-footer-layout__title {
        &:hover {
          text-decoration: underline;
          text-decoration-thickness: 0.5px;
          color: var(--color-gray-light);
          transition: all 0.5s ease;
        }

        &:active {
          color: var(--color-gray);
        }
      }
    }

    &__contact {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__mail {
        display: flex;
        align-items: center;

        .c-icon {
          fill: var(--color-goldenrod);
          margin-right: 12px;
        }

        .c-link-url {
          text-decoration: none;
          color: var(--color-goldenrod);
        }
      }
    }
  }

  &__block-2 {
    width: 100%;
    display: grid;
    grid-template-columns:  280px 1fr 320px;
    align-items: center;
    grid-gap: 24px;
    padding: 24px 0px 0px;
    border-top: 1px solid var(--color-gray);

    .c-copyright {
      font-size: 12px;
    }

    &__author {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 24px;

      .c-link-url {
        background-color: var(--color-white);
        padding: 4px 8px;
        display: flex;
        align-items: center;
        
        .c-footer-layout__block-2__author__logo {
          height: 16px;
        }
      }
    }

    &__social-media {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__list {
        display: flex;
        align-items: center;

        .c-link-url {
          margin-right: 24px;
          height: 24px;

          &:last-child {
            margin-right: 0px;
          }
          
          .c-icon {
            fill: var(--color-goldenrod);
          }

          &:hover {
            transform: scale(1.1);
            transition: all 0.5s ease;
          }
  
          &:active {
            transform: scale(1.2);
            transition: all 0.5s ease;
          }
  
        }
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .c-footer-layout__block-1 {
    grid-template-columns: 1fr 2fr 200px;

    &__page {
      flex-direction: column;
      align-items: center;
      gap: 4px;
    }

    &__contact {
      flex-direction: column;
      gap: 4px;
    }
  }

  .c-footer-layout__block-2 {
    grid-template-columns: 1fr 2fr 200px;

    .c-copyright {
      flex-direction: column;
    }

    &__author {
      flex-direction: column;
      align-items: center;
      gap: 4px;
    }

    &__social-media {
      flex-direction: column;
      gap: 4px;
    }
  }
}

@media screen and (max-width: 768px) {
  .c-footer-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }

  .c-footer-layout__title {
    font-size: 22px;
  }

  .c-footer-layout__block-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-width: 328px;

    &__logo {
      align-self: center;
    }

    &__page {
      align-items: start;
      gap: 8px;
    }

    &__contact {
      gap: 8px;
    }
  }

  .c-footer-layout__block-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-width: 328px;

    .c-copyright {
      flex-direction: row;
      order: 3;
      font-size: 13px;
      min-width: auto;
    }

    &__author {
      flex-direction: row;
      justify-content: start;
      gap: 16px;
      width: 100%;
    }

    &__social-media {
      gap: 8px;
    }
  }
}
