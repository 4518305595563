@import '../../assets/styles/mixins';

.c-tag-list {
  width: 100%;
  position: relative;
  margin-bottom: 16px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: var(--background-layer-tetriary);

    &__input {
      display: flex;
      flex-direction: row;
      margin-right: 8px;
      justify-content: space-between;
      width: calc(100% - 150px);
  
      &__field {
        height: 24px;
        outline: none;
        border: none;
        border-bottom: 1px solid var(--text-color-tetriary);
        background-color: inherit;
        color: var(--text-color-primary);
        width: calc(100% - 40px - 4px);
        padding: 4px;
        margin-right: 8px;
        
        &::placeholder {
          color: var(--text-color-tetriary);
        }
      }
      
      &__option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 36px;
    
        .c-icon-save {
          fill: var(--color-aqua);
        }
    
        .c-icon-cross-round {
          fill: var(--color-coral);
        }
      }
    }
  }

  &__tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: rgba(#F2F2F2, 0.8);
    z-index: 999;
    position: absolute;
    width: 100%;
    max-height: 107px;
    padding: 8px 0px 4px 0px;
    overflow: hidden;
    overflow-y: scroll;
    @include custom-scroll-bar();

    & .c-tag {
      background-color: rgba(#eddb73, 0.8);
      margin: 0px 4px 4px 0px;
    }
  }

  &__input-tag {
    position: relative;
  
    .c-icon-cross-round {
      display: none;
      position: absolute;
      fill: var(--color-black);
      right: 4px;
      bottom: 21px;
      width: 10px;
    }
            
    &:hover {
      .c-icon-cross-round {
        display: inline-block;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    min-height: 40px;
    max-height: 107px;
    padding: 8px 0px 8px 0px;
    overflow: hidden;
    overflow-y: scroll;
    @include custom-scroll-bar();


    &__tag {
      margin: 0px 4px 4px 0px;
      position: relative;


      .c-icon-cross-round {
        display: none;
        position: absolute;
        fill: var(--color-blue);
        right: 1px;
        bottom: 16px;
        width: 10px;
      }

      &:hover {
        .c-icon-cross-round {
          display: inline-block;
        }
      }
    }

    &__empty-state {
      color: var(--text-color-tetriary);
      padding-left: 8px;
    }
  }

  &.mobile {
    margin-bottom: 0px;

    .c-tag-list__header {
      flex-wrap: wrap;
      padding: 0px;
      margin-bottom: 16px;
  
      &__title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 36px;

        &__name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .c-task__title {
          font-size: 16px;
          font-weight: 500;
        }

        .c-icon-plus {
          stroke: var(--background-icon-color-gray);
          margin-left: 16px;
          cursor: pointer;
          align-self: center;
        }
      }
  
      &__input {
        width: 100%;
        margin-right: 0px;
        margin-top: 8px;
      }
    }

    .c-tag-list__list {
      padding: 0px;
      min-height: initial;

      &__tag {
        .c-tag {
          padding-right: 28px;
        }

        .c-icon-plus {
          position: absolute;
          stroke: var(--color-graphite);
          transform: rotate(-45deg);
          right: 8px;
          bottom: 6px;
          width: 14px;
        }
      }
  
      &__empty-state {
        padding-left: 0px;
      }
    }
  }
}

.c-task_full-screen, .c-task-mobile {
  .c-tag-list__list {
    overflow: visible;
    max-height: initial;
  }

  .c-tag {
    max-width: 158px;
  }
}

html[data-theme='dark'] {
  .c-tag-list {
    &__tags {
      background-color: rgba(#383838, 0.8);

      .c-tag {
        background-color: var(--color-gray-light);
      }

      .c-icon-cross-round {
        fill: var(--color-white);
      }

    }

    &__list__tag {
      .c-icon-cross-round {
        fill: var(--color-white);
      }
    }
  }

  .c-tag-list.mobile {
    .c-tag-list__list__tag {
      .c-icon-plus {
        stroke: var(--color-white);
      }
    }
  }
}
