.c-user-field {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  font-weight: 500;
  
  &_xl {
    font-size: 20px;
    line-height: 24px;
  }

  &_lg {
    font-size: 18px;
    line-height: 22px;
  }

  &_md {
    font-size: 16px;
    line-height: 20px;
  }

  &_sm {
    font-size: 14px;
    line-height: 18px;
  }

  &_xs {
    font-size: 12px;
    line-height: 16px;
  }

  .c-user-avatar {
    margin-right: 8px;
  }

  &__user-info {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__user-name {
    font-family: inherit;
    white-space: nowrap;
  }

  &__user-email {
    font-family: inherit;
    white-space: nowrap;
  }

  &.clickable {
    cursor: pointer;
  }
}
