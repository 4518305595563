.c-mobile-menu {
  position: absolute;
  top: 0px;
  right: 0px;
  border: 1px solid var(--border-color-main);
  background-color: var(--background-layer-quaternary);
  padding: 0px 24px 24px;
  width: 100%;
  height: 100vh;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 9px 0px;
    margin-bottom: 24px;

    .c-logo__name__version {
      color: var(--text-color-mobile-menu);
    }

    .c-icon-chevron-down {
      fill: var(--background-color-mobile-menu-secondary);
      rotate: (-90deg);
    }
  }

  &__user {
    margin-bottom: 16px;

    .c-user-field {
      grid-template-columns: auto;
      grid-template-rows: auto auto;
      grid-gap: 8px;

      &__user-name {
        font-size: 16px;
        font-weight: 700;
        color: var(--text-color-primary);
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    color: var(--text-color-mobile-menu);
    margin-bottom: 24px;
    border-top: 1px solid var(--border-color-mobile-menu);
    padding-top: 24px;

    & .c-link-router {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      padding: 12px;
      border: 1px solid var(--border-color-mobile-menu);
      border-radius: 12px;

      .c-icon-chevron-down {
        fill: var(--background-color-mobile-menu-primary);
        rotate: (-90deg);
        min-width: 16px;
      }
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 22px;
      font-weight: 500;
      padding: 12px;
      background-color: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
      color: var(--color-white);
      border-radius: 12px;

      .c-icon-chevron-down {
        fill: var(--color-gray);
        rotate: (-90deg);
      }
    }
  }

  & .c-button {
    width: 100%;
    font-weight: 700;
  }
}

@media screen and (min-width: 768px) {
  .c-mobile-menu {
    display: none;
  }
}
