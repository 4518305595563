.v-auth {
  /*height: calc(100vh - header  - footer);*/ 
  min-height: calc(100vh - var(--header-height)  -  var(--footer-height));
	display: flex;
	justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .v-auth { 
    /*height: calc(100vh - header - footer);*/ 
    min-height: calc(100vh - var(--header-height)  -  var(--footer-height));
  }    
}

@media screen and (max-width: 768px) {
  .v-auth { 
    /*height: calc(100vh - header - footer);*/ 
    min-height: calc(100vh - var(--header-height)  -  var(--footer-height));
    padding: 0px;
  }    
}
