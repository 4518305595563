.c-board-panel-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  width: 100%;
  margin-bottom: 8px;

  & .c-heading {
    display: flex;
    align-items: center;
    margin-bottom: 0px;

    .c-board-panel-top__title {
      font-size: 24px;
      line-height: 1.5;
      margin-right: 8px;
    }
  }

  & .c-tooltip {
    &-massage {
      &__text {
        margin-bottom: 8px;
      }

      & .c-button {
        width: initial;
      }
    }
  }

  .c-sort-board {
    margin-right: 10px;
  }

  .c-button {
    white-space: nowrap;
    
    .c-icon-plus {
      display: block;
    }
  }

  .c-icon-star {
    fill: var(--color-brand);
  }

  .c-icon-user-shield, .c-icon-users, .c-icon-folder {
    fill: var(--background-icon-color-gray);
  }

  .c-icon-plus {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .c-board-panel-top {  
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 8px;

    .c-icon-plus {
      display: block;
      stroke: var(--color-graphite);

      svg {
        width: 16px;
      }
    }

    .c-button {
      display: none;
    }
  }
}

html[data-theme='dark'] {
  @media screen and (max-width: 767px) {
    .c-board-panel-top {
      .c-icon-plus {
        stroke: var(--color-white);
      }
    }
  }
}
