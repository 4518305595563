@import '../../assets/styles/mixins';

.c-task-logged-time {
  &__isModalMode {
    margin-bottom: 16px;

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      background-color: var(--background-layer-tetriary);
    }

    &__time-log {
      font-size: 14px;
      line-height: 1.5;
      padding: 8px;
      display: grid;
      grid-template-columns: 80px 2fr 1fr;
      align-items: center;
      color: var(--text-color-tetriary);
    }

    &__total {
      color: var(--color-gray);
    }

    &__empty-state {
      color: var(--color-gray);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    &__view {
      color: var(--info-color);
      cursor: pointer;
      justify-self: end;

      &:hover {
        color: var(--text-color-tetriary);
        transition: all ease 0.3s;
      }
    }
  }

  &__full-screen {
    margin-bottom: 16px;

    &__header {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding: 8px;
      background-color: var(--background-layer-tetriary);

      &__title {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    &__time-log {
      font-size: 14px;
      line-height: 18px;

      &__header {
        padding: 8px;
        display: grid;
        grid-template-columns: 4fr 2fr 2fr 20px 20px;
        align-items: center;
        color: var(--text-color-tetriary);
      }

      &__list {
        max-height: 420px;
        overflow-y: auto;
        margin: 5px 0px;

        &__item {
          padding: 4px 8px;
          display: grid;
          grid-template-columns: 4fr 2fr 2fr 20px 20px;
          align-items: center;

          & .c-icon-edit,
          .c-icon-bin {
            display: none;
          }

          & .c-icon-edit {
            stroke: var(--background-icon-color-blue);
          }

          & .c-icon-bin {
            fill: var(--color-coral);
          }

          &:hover {
            color: var(--info-color);
            transition: all 0.3s ease;

            & .c-icon-edit,
            .c-icon-bin {
              display: block;
            }
          }
        }

        @include custom-scroll-bar();
      }

      &__footer {
        padding: 8px;
        display: grid;
        grid-template-columns: 4fr 2fr 2fr 20px 20px;
        align-items: center;
        background-color: var(--background-layer-tetriary);
        color: var(--text-color-tetriary);
      }
    }

    &__empty-state {
      padding: 8px;
      color: var(--text-color-tetriary);
    }
  }

  &__full-screen.mobile {
    margin-bottom: 0px;

    .c-task-logged-time__full-screen__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      background-color: inherit;
      padding: 0px;
  
      &__title {
        display: flex;
        justify-content:flex-start;
        align-items: center;
        gap: 16px;
      }
  
      .c-task__title {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
      }
  
      .c-icon-plus {
        stroke: var(--background-icon-color-gray);
        cursor: pointer;
      }
    }

    .c-task-logged-time__full-screen__time-log {
      &__list {
        max-height: none;
        overflow-y: auto;
        margin: 0px;

        &__item {
          border-bottom: 1px solid var(--border-color-main);
          grid-template-columns: 2fr 2fr 2fr 30px 30px;
          justify-items: center;
          padding: 0px 0px 8px;
          margin-bottom: 8px;
          font-size: 13px;

          & .c-user-field {
            justify-self: flex-start;
            
            &__user-info {
              display: none;
            }
          }

          & .c-icon-edit,
          .c-icon-bin {
            display: block;
            justify-self: end;
          }
        }
      }

      &__footer {
        grid-template-columns: 28px 1fr 1fr 60px;
        background-color: inherit;
        font-size: 16px;
        padding: 0px;

        &__total {
          padding: 8px 4px;
          background-color: var(--color-mint-light);
          border-radius: 4px;
          text-align: center;
          font-size: 13px;
          font-weight: 700;
        }

        .c-icon-clock {
          fill: var(--background-icon-color-gray);
        }
      }
    }

    .c-task-logged-time__full-screen__empty-state {
      padding: 0px;
    }
  }
}

.c-modal.mobile.modal-task-logged-time {
  .c-modal__footer {
    flex-direction: column;

    & .c-button:first-child {
      margin-bottom: 16px;
    }
  }
}
