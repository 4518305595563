.c-tooltip {
  position: relative;

  &__content {
    position: absolute;
    z-index: 998;
    font-size: 14px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    width: 200px;
    background: var(--background-layer-quaternary);
    border: 1px solid var(--border-color-main);
    color: var(--text-color-primary);
    border-radius: 4px ;
    padding: 8px 12px;

    &::before {
      position: absolute;
      content: "";
      background-color: var(--background-layer-quaternary);
      width: 12px;
      height: 12px;
      border: 1px solid var(--border-color-main);
      border-width: 1px 1px 0px 0px;
    }

    &_top {
      left: 0;
      bottom: calc(100% + 10px);

      &::before {
        left: 8px;
        bottom: -7px;
        transform: rotate(135deg);
      }
    }

    &_bottom {
      left: 0;
      top: calc(100% + 10px);

      &::before {
        left: 8px;
        top: -7px;
        transform: rotate(-45deg);
      }
    }

    &_right {
      top: 0px;
      left: calc(100% + 10px);

      &::before {
        top: 8px;
        left: -7px;
        transform: rotate(-135deg);
      }
    }

    &_left {
      top: 0px;
      right: calc(100% + 10px);

      &::before {
        top: 8px;
        right: -7px;
        transform: rotate(45deg);
      }
    }
  }
}
