.c-privacy-policy {
  display: flex;

  & .c-heading {
    font-weight: bold;
    margin-bottom: 16px;
  }

  &__sidebar, &__content {
    background: var(--background-layer-secondary);
    border: 1px solid var(--border-color-main);
    border-radius: 4px;
    box-shadow: var(--box-shadow-primary);
    padding: 48px 24px;
  }

  &__sidebar {
    width: 260px;
    height: 100vh;
    margin-right: 24px;
    position: sticky;
    top: 92px;

    &__item {
      margin-bottom: 12px;

      &__link {
        text-decoration: none;
        color: inherit;
        cursor: pointer;

        &:hover {
          color: var(--text-color-quaternary);
          transition: all ease 0.3s;
        }
      }
    }

    & .c-color-mark-bar_right {
      height: calc(100% + 2px);
      top: -1px;
      right: -1px;
    }
  }

  &__content {
    width: calc(100% - 260px);

    &__wrapper {
      max-width: 800px;
      margin: auto;
    }

    &__date {
      font-style: italic;
      margin-bottom: 16px;
    }

    &__section {
      margin-bottom: 16px;

      &__title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 4px;
      }

      &__list {
        list-style: inside;
      }

      &__link {
        text-decoration: none;
        color: var(--info-color);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .c-privacy-policy {
    width: 100%;

    &__content {
      width: 100%;
      background: none;
      border: none;
      box-shadow: none;
      padding: 0;

      &__section {
        border: 1px solid var(--border-color-main);
        box-shadow: var(--box-shadow-primary);
        margin-bottom: 20px;
        background-color: var(--background-layer-secondary);
        border-radius: 4px;
        padding: 16px;
        
        &__title {
          font-size: 24px;
          margin-bottom: 20px;
        }

        &__link {
          word-wrap: break-word;
        }

        &__list {
          list-style: outside;
          padding-left: 25px;
        }
      }
    }
    &__sidebar {
      display: none;
    }

    .c-heading__content {
      font-size: 32px;
    }
  }
}
