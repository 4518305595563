:root {
  --header-height: 68px;
  --footer-height: 165px;

  --footer-height-medium: 257px;
  --footer-height-large: 509px;
}

@media (max-width: 1360px) {
  :root {
    --footer-height: var(--footer-height-medium);
  }
}

@media (max-width: 768px) {
  :root {
    --footer-height: var(--footer-height-large);
  }
}