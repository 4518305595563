.v-no-access {
  display: flex;

  .c-panel {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }

  .c-icon-lock {
    fill: none;
    stroke: var(--danger-color);
  }
}
