.v-error {
  display: flex;

  .c-panel {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }

  .c-icon-error-page {
    fill: var(--danger-color);
  }
}
