@import '../../assets/styles/variables';

.c-main-layout {
  min-height: 100vh;
  min-width: 360px;
  width: 100%;

  .app-content {
    min-height: auto;
    background: var(--background-layer-primary);
  }
}

[class^="v-"] {
  width: 100%;
  min-height: calc(100vh - var(--header-height)  - 40px - var(--footer-height));
  padding: 24px;
}

.v-loader {
  min-height: calc(100vh - var(--header-height)  -  var(--footer-height));
}

@media screen and (max-width: 768px) {
  .c-main-layout {
    &:has(.c-modal__overlay,.actions-mode) {
      position: fixed;
      height: 100vh;
      overflow: hidden;
    }
  }
}