.c-task {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 760px;
  height: 824px;
  border-radius: 4px 0px 0px 4px;
  background-color: var(--background-layer-secondary);
  box-shadow: var(--box-shadow-primary);
  border: 1px solid var(--border-color-main);
  border-left: 0px;
  display: grid;
  grid-template-columns: 1fr 300px;
  font-size: 14px;

  &.c-task_full-screen { 
    position: relative;
    top: initial;
    left: initial;
    z-index: 1;
    transform: initial;
    max-width: 1000px;
    min-height: calc(100vh - var(--header-height) - 40px - 48px - var(--footer-height));
    height: auto;
    margin: auto;
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
  }

  &__content {
    padding: 16px;
    max-width: 500px;
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: 90vh;

    .c-task_full-screen & {
      max-width: 700px;
      max-height: initial;
      height: 100%;

      .ProseMirror {
        height: auto;
        min-height: 30vh;
      }
    }
  }

  &__aside {
    padding: 16px 16px 46px;
    background-color: var(--background-layer-tetriary);
    position: relative;

    &:has(.c-task-save) {
      padding: 16px 16px 78px;
    }

    &__header-action {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 16px;

      .c-icon-cross-round {
        fill: var(--background-icon-color-blue);
      } 
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-color-overlay);
  opacity: 0.4;
  z-index: 1000;
}
