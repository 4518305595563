.c-auth-form-nav {
  &__tabs {
    border-radius: 4px 4px 0px 0px;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;

    li {
      width: 50%;
      text-align: center;
      font-size: 24px;
      cursor: pointer;
      line-height: 1.5;
      height: 40px;
      background-color: var(--background-layer-tetriary);
      border-bottom: 4px solid var(--color-graphite-light);
      color: var(--text-color-tetriary);

      &:nth-child(1) {
        border-radius: 4px 0px 0px;
      }

      &:nth-child(2) {
        border-radius: 0px 4px 0px 0px;
      }
    }

    &.login {
      .c-auth-form-nav__tab.login {
        color: var(--text-color-primary);
        background-color: var(--background-layer-secondary);
        border-bottom: 4px solid var(--color-brand);
      }
    }

    &.register {
      .c-auth-form-nav__tab.register {
        color: var(--text-color-primary);
        background-color: var(--background-layer-secondary);
        border-bottom: 4px solid var(--color-brand);
      }
    }
  }
}
