.c-checkbox-control {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 
    'header header'
    'side content'
    'error error';
  line-height: 1.5em;

  &_required {
    .c-checkbox-control__title {
      &::after {
        position: absolute;
        top: -6px;
        color: red;
        font-weight: 900;
        content: "*";
      }
    }
  }

  &__title {
    font-size: 16px;
    padding-left: 4px;
    margin-bottom: 9px;
    grid-area: header;
  }

  &__input[type='checkbox'] {
    grid-area: side;
    appearance: none;
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    background-color: var(--background-layer-tetriary);
    border: 1px solid var(--border-color-main);
    cursor: pointer;

    &:checked::after {
      content: "";
      display: inline-block;
      position: relative;
      width: 6px;
      height: 3px;
      bottom: 101%;
      left: 1px;
      background-color: var(--confirm-color);
      transform: rotate(47deg);
      border-radius: 1px;
    }
    &:checked::before {
      content: "";
      display: inline-block;
      position: relative;
      width: 82%;
      height: 3px;
      bottom: 13%;
      left: 3px;
      background-color: var(--confirm-color);
      border-radius: 1px;
      transform: rotate(135deg);
    }
  }

  &__description {
    font-size: 12px;
    grid-area: content;
    padding-left: 5px;
  }
  .disable {
    &:checked::after {
      content: "";
      display: inline-block;
      position: relative;
      width: 6px;
      height: 3px;
      bottom: 101%;
      left: 1px;
      background-color: var(--color-gray-light);
      transform: rotate(47deg);
      border-radius: 1px;
    }
    &:checked::before {
      content: "";
      display: inline-block;
      position: relative;
      width: 82%;
      height: 3px;
      bottom: 13%;
      left: 3px;
      background-color: var(--color-gray-light);
      border-radius: 1px;
      transform: rotate(135deg);
    }
  }
  .c-checkbox-control__bottom-text {
    color: var(--danger-color);
    padding-left: 12px;
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
    grid-area: error;

  }
}
