.c-status-card-add-button {
  width: 216px;
  min-width: 216px;
  min-height: 300px;
  border-radius: 4px;
  background-color: var(--background-layer-tetriary);
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  opacity: 0.4;
  order: 99999;

  .c-button {
    width: 50px;
    min-width: 50px;
    height: 50px;
    margin: 8px 0px;
    background-color: inherit;

    .c-button__icon+span {
      margin-left: 0px;
    }
  }

  &__text {
    color: var(--color-blue);
  }
  
  & .c-color-mark-bar {
    border-color: var(--mark-color-blue);
  }

  &:hover {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
}


@media screen and (max-width:767px) {
  .c-status-card-add-button {
    min-height: auto;
    width: 100%;
    padding: 12px;
    background-color: var(--color-silver);
    border-radius: 12px;
    flex-direction: row;

    .c-button {
      color: var(--color-black);
      height: 28px;

      &#button_addColumn_statusCardAddButton {
        color: var(--color-black);
        border: none;
        margin: 0;
        height: auto;
        padding-left: 0px;
      }

      &__icon {
        .c-icon.c-icon-plus {
          fill: var(--color-black);
          stroke: var(--color-black);
          border: 1px solid var(--color-black);
          border-radius: 4px;
          width: 28px;
          height: 28px;
          padding: 2px;
        }
      }
    }

    &__text {
      color: var(--color-black);
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
    }
  }

}