.c-breadcrumb {
  padding: 16px 24px 0px;
  width: 100%;

  &__link {
    color: var(--text-color-quaternary);
    position: relative;
    margin-right: 12px;
  }

  &__link:not(:last-child)::after {
    content: "/";
    position: absolute;
    right: -8px;
    color: var(--text-color-quaternary);
  }

  &__link:last-child {
    color: var(--text-color-tetriary);
    text-decoration: none;
    cursor: auto;
  }
}
