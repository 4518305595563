@import '../../assets/styles/mixins';

.c-text-editor {
  margin-bottom: 16px;

  &__toolbar {
    padding: 0px;

    &__block {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-bottom: 4px;
    }

    &__heading {
      display: inline-block;
      position: relative;
      width: 110px;
      margin-right: 4px;

      &__button {
        border: 1px solid var(--border-color-main);
        color: var(--text-color-tetriary);
        font-weight: 500;
        border-radius: 4px;
        padding: 4px;
        padding-left: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &__dropdown {
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 1002;

        &__item {
          background-color: var(--background-layer-secondary);
          border: 1px solid var(--border-color-main);
          color: var(--text-color-tetriary);
          padding-left: 10px;
          cursor: pointer;
          border-bottom: none;
          
          &:first-child {
            border-radius: 4px 4px 0px 0px;
          }

          &:last-child {
            border-bottom: 1px solid var(--border-color-main);
            border-radius: 0px 0px 4px 4px;
          }

          &:hover {
            background-color: var(--background-layer-tetriary);
          }
        }
      }
    }

    &__button {
      background-color: initial;
      border: 1px solid var(--background-layer-secondary);
      border-radius: 4px;
      padding: 8px;
      margin-right: 4px;
      cursor: pointer;

      svg {
        fill: var(--background-icon-color-gray);
      }

      &.is-active {
        svg {
          fill: var(--text-color-quaternary);
        }
      }

      &:disabled {
        & svg {
          opacity: 0.2;
        }
      }

      &:last-child, &:last-of-type {
        margin-right: 0px;
      }

      &:hover {
        background-color: var(--background-layer-tetriary);
        border: 1px solid var(--border-color-main);
      }
    }

    &__link-input {
      height: 40px;
      width: 100%;
      background-color: var(--color-silver-light);
      border: var(--color-silver-light);

      &:focus {
        outline: none;
      }
    }

    &__color {
      position: relative;
      display: inline-block;

      &__palette {
        position: absolute;
        top: 30px;
        left: 0px;
        z-index: 1002;
        border-radius: 4px;
        background-color: var(--background-layer-secondary);
        box-shadow: var(--border-color-main) 0px 0px 0px inset, var(--border-color-main) 0px 0px 0px 1px inset;
        padding: 8px 4px;

        & .c-color-palette {
          box-shadow: none;
          margin-bottom: 8px;
          padding: 0px;
          justify-content: center;
        }

        & .c-button {
          display: flex;
          margin: auto;
        }
      }
    }

    &__align {
      position: relative;
      display: inline-block;
      width: 72px;
      margin-right: 4px;

      &__button {
        border: 1px solid var(--border-color-main);
        border-radius: 4px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      svg {
        fill: var(--background-icon-color-gray);
      }

      &__dropdown {
        position: absolute;
        left: 0;
        z-index: 1002;
        height: 34px;

        &__item {
          background-color: var(--background-layer-secondary);
          border: 1px solid var(--border-color-main);
          border-right: none;
          padding: 8px;
          cursor: pointer;

          &:first-child {
            border-radius: 4px 0px 0px 4px;
          }

          &:last-child {
            border-right: 1px solid var(--border-color-main);
            border-radius: 0px 4px 4px 0px;
          }

          &:hover {
            background-color: var(--background-layer-tetriary);
          }
        }
      }
    }
  }

  .ProseMirror {
    background-color: var(--background-layer-quaternary);
    border: 1px solid var(--border-color-main);
    border-radius: 4px;
    color: var(--text-color-primary);
    padding: 4px;
    line-height: 1.5;
    overflow-y: auto;
    height: 202px;
    @include custom-scroll-bar();
  
    &-focused {
      outline: none;
    }
  
    ul,
    ol {
      padding-left: 16px;
    }
  
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
      background: var(--background-layer-additional);
      color:var(--text-color-primary);
      padding: 4px;
      line-height: 1;
    }

    pre {
      background: var(--background-layer-additional);
      padding: 4px 4px 0px;

      &:last-of-type {
        padding: 4px;
      }
  
      code {
        background: none;
        padding: 0px;
      }
    }
  
    blockquote {
      padding-left: 8px;
      border-left: 4px solid var(--color-silver);
    }

    em {
      font-style: italic;
    }
    
    ol {
      list-style-type: decimal;
    }
    
    ul {
      list-style-type: disc;
    }
    
    h1 {
      font-size: 2em;
    }
    
    h2 {
      font-size: 1.5em;
    }
    
    h3 {
      font-size: 1.17em;
    }
    
    h4 {
      font-size: 1em;
    }
    
    h5 {
      font-size: 0.83em;
    }
    
    h6 {
      font-size: 0.67em;
    }
    
    sup {
      vertical-align: super;
      font-size: smaller;
    }
    
    sub {
      vertical-align: sub;
      font-size: smaller;
    }

    a {
      cursor: pointer;
      color: var(--text-color-quaternary);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.mobile {
    margin-bottom: 8px;
    
    .c-text-editor__toolbar {
      &__button {
        border: 1px solid var(--background-layer-primary);
      }

      &__color {
        flex-grow: 1;
  
        &__palette {
          right: 0px;
          top: 41px;
          left: initial;
        }
  
        .c-text-editor__toolbar__button {
          width: 100%;
        }
      }

      &__button {
        flex-grow: 1;
      }
    }

    .ProseMirror {
      padding: 16px;
      min-height: 130px;
      height: auto;
    }
  }

  .react-tooltip {
    z-index: 1002;
  }
}
