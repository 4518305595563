 .c-task-card-footer {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 1fr;
  align-items: center;
  justify-items: stretch;
  font-size: 10px;
  color: var(--text-color-tetriary);
  height: 24px;
  width: 100%;

  &__user {
    width: 24px;
    height: 24px;

    & .c-user-list.c-user-list {
      padding: 0px;
    }

    & .c-user-avatar {
      width: 24px;
      height: 24px;
    }
  }
  
  &__time {
    justify-self: start;
    line-height: 1;
    margin-left: 8px;

    &__estimation, &__log-time {
      width: 100%;
      max-width: 64px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .high-level {
      color: var(--danger-color);
    }
  }

  &__date {
    font-weight: 400;
    justify-self: center;
    line-height: 1;

    & .high-level {
      color: var(--danger-color);
    }
  }

  & .c-priority-icon  {
    height: 24px;
  }
}


@media screen and (max-width:767px) {
    .c-task-card-footer {
      height: 20px;

      .c-user-list {
        &__user {
          width: 20px;
          height: 20px;

          .c-user-avatar {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
}