@import './reset';
@import './color';
@import './typography';
@import './mixins';
@import './keyframes';

body {
  margin: 0;
  font-family: var(--main-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color-primary);
  line-height: 1.5;
  @include custom-scroll-bar();

  &:has(.c-task, .c-mobile-menu) {
    overflow-y: hidden;
  }

  &:has(.c-task.c-task_full-screen) {
    overflow-y: auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

// .btn {
//   line-height: 26px;
//   padding: 4px 12px;
//   background-color: var(--color-violet);
//   border: 1px solid var(--color-violet);
//   margin-right: 0;
//   color: white;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: .2s;
// }

// .btn.small {
//   padding: 0 8px;
// }

// .btn.accept {
//   background-color: var(--color-aqua);
//   border: 1px solid var(--color-aqua);
// }

// .btn.danger {
//   background-color: var(--danger);
//   border: 1px solid var(--danger);
// }

// .btn.cancel {
//   background-color: var(--yellow);
//   border: 1px solid var(--yellow);
// }

// .btn:hover {
//   opacity: 0.8;
// }

// .btn:disabled {
//   opacity: .3;
//   cursor: default;
// }

// .btn.icon svg {
//   position: relative;
//   top: 4px;
//   margin-right: 6px;
// }

// .btn-icon {
//   border: none;
//   cursor: pointer;
// }

// .panel {
//   background-color: var(--background-color-white);
//   box-shadow: var(--box-shadow-primary);
//   border-radius: 4px;
// }
