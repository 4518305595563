.c-user-profile {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 56px;
  font-size: 1em;
  line-height: 1.5em;

  &__data {
    display: flex;
    max-width: 600px;
  }

  &__left-side {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  &__right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__email {
    color: var(--color-graphite-light);
  }

  .c-paragraph {
    &:nth-child(1) {
      font-weight: 400;
    }

    &:nth-child(2) {
      line-height: 1em;
      color: var(--color-gray);
    }
  }

  .c-link-url {
    text-decoration: none;
    line-height: 1em;
  }

  .c-loader {
    #L7 {
      height: 56px;
      width: 56px;
    }
  }

  .c-icon-envelope {
    fill: var(--color-gray);
    position: relative;
    top: 10%;
    margin-right: 10px;
  }
}
