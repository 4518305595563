.c-modal-create-board {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    color: var(--color-black);
  
    .c-modal {
      &__header {
        margin-bottom: 8px;
      }
  
      &__content {
        margin-bottom: 16px;
  
        .c-heading {
          &:first-child {
            margin-bottom: 8px;
          }
  
          &__content {
            width: auto;
          }
  
          &:has(.c-heading__action) {
            .c-heading__content {
              width: 100%;
            }
          }
  
          &:nth-child(2) {
            font-size: 16px;
          }
        }
      }
  
      &__footer {
        justify-content: flex-end;
  
        .c-button.confirm {
          background-color: var(--color-blue);
          border: none;
        }
      }
    }
 }
