.c-notification {
  font-size: 20px;
  line-height: 30px;
  display: inline-block;
  padding: 6px 10px 6px 16px;
  border-radius: 6px;
  background-color: var(--confirm-color);

  &.error {
    background-color: var(--danger-color);
  }

  &.warning {
    background-color: var(--warning-color);
  }

  &__text {
    color: var(--color-white);
    margin-right: 20px;
  }

  .c-icon-plus {
    stroke: var(--color-white);
    stroke: var(--color-white);
    transform: rotate(45deg);
    width: 12px;
  }
}
