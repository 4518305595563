:root {

  // STATIC COLOR
  --color-air-blue: #719FBE;
  --color-aqua-light: #D6FFF6;
  --color-aqua: #4DCCBD;
  --color-aquamarine: #7FFFD4;
  --color-azure: #205B85;
  --color-black: #242424;
  --color-blue: #2374AB;
  --color-brand: #f6be00;
  --color-charcoal: #414B64;
  --color-cobalt: #003F69;
  --color-coral: #FF8484;
  --color-deep-black: #1A1A1A;
  --color-deep-gray: #595858;
  --color-goldenrod: #EDDA74;
  --color-graphite-light: #515151;
  --color-graphite: #2C2C2C;
  --color-gray-light: #A4A4A4;
  --color-gray: #797979;
  --color-green: #04FB84;
  --color-jet-black: #383838;
  --color-lime: #6FCF97;
  --color-mint-light: #D9F4ED;
  --color-orange: #FFA800;
  --color-pine-green: #148376;
  --color-pink: #F660AB;
  --color-purple: #8E35EF;
  --color-red: #FA4D4D;
  --color-rosso: #E01D1D;
  --color-silver-light: #F2F2F2;
  --color-silver: #D4D4D4;
  --color-turquoise: #549F96;
  --color-venetian-red: #C80815;
  --color-violet: #231651;
  --color-white: #FFFFFF;

  // DINAMIC COLOR

  // Text
  --text-color-additional: var(--color-gray);
  --text-color-mobile-menu:  var(--color-graphite-light);
  --text-color-primary: var(--color-graphite);
  --text-color-quaternary: var(--color-blue);
  --text-color-secondary: var(--color-white);
  --text-color-tetriary: var(--color-graphite-light);

  // Background
  --background-color-mobile-menu-primary:  var(--color-graphite-light);
  --background-color-mobile-menu-secondary:  var(--color-graphite-light);
  --background-color-overlay: var(--color-graphite);
  --background-icon-color-blue: var(--color-blue);
  --background-icon-color-gray: var(--color-gray);
  --background-icon-color-primary: var(--color-graphite);
  --background-image: url("../../../public/images/screen_board_light.png");
  --background-layer-additional: var(--color-silver);
  --background-layer-primary: var(--color-silver-light);
  --background-layer-quaternary: var(--color-white);
  --background-layer-secondary: var(--color-white);
  --background-layer-tetriary: var(--color-silver-light);

  // Border
  --border-color-main: var(--color-silver);
  --border-color-mobile-menu: var(--color-graphite-light);
  --border-color-secondary: var(--color-graphite-light);

  // Box-shadow
  --box-shadow-primary: 0px 2px 4px #88ADC740;

  // Semantic colors
  --confirm-color: var(--color-aqua);
  --danger-color: var(--color-red);
  --info-color: var(--color-blue);
  --warning-color: var(--color-orange);

  // Mark colors
  --mark-color-aqua: var(--color-aqua);
  --mark-color-aquamarine: var(--color-aquamarine);
  --mark-color-blue: var(--color-blue);
  --mark-color-coral: var(--color-coral);
  --mark-color-goldenrod: var(--color-goldenrod);
  --mark-color-graphite-light:var(--color-graphite-light);
  --mark-color-green: var(--color-green);
  --mark-color-pink: var(--color-pink);
  --mark-color-purple: var(--color-purple);
  --mark-color-red: var(--color-red);

  // Priority colors
  --priority-color-high: var(--color-coral);
  --priority-color-low: var(--color-silver);
  --priority-color-medium: var(--color-goldenrod);
}

html[data-theme='dark'] {

  // Text
  --text-color-additional: var(--color-gray-light);
  --text-color-mobile-menu:  var(--color-goldenrod);
  --text-color-primary: var(--color-white);
  --text-color-quaternary: var(--color-brand);
  --text-color-secondary: var(--color-graphite);
  --text-color-tetriary: var(--color-gray-light);

  // Background
  --background-color-mobile-menu-primary:  var(--color-goldenrod);
  --background-color-mobile-menu-secondary:  var(--color-brand);
  --background-color-overlay: var(--color-white);
  --background-icon-color-blue: var(--color-air-blue);
  --background-icon-color-gray: var(--color-gray-light);
  --background-icon-color-primary: var(--color-white);
  --background-image: url("../../../public/images/screen_board_dark.png");
  --background-layer-additional: var(--color-gray);
  --background-layer-primary: var(--color-deep-black);
  --background-layer-quaternary: var(--color-graphite-light);
  --background-layer-secondary: var(--color-black); 
  --background-layer-tetriary: var(--color-jet-black);

  // Border
  --border-color-main: var(--color-jet-black);
  --border-color-mobile-menu: var(--color-goldenrod);
  --border-color-secondary: var(--color-silver);

  // Box-shadow
  --box-shadow-primary: 0px 4px 6px 2px #1D1C1C;

  // Semantic colors
  --confirm-color: var(--color-turquoise);
  --info-color: var(--color-air-blue);
}
