.c-task-estimation-time {
    display: grid;
    grid-template-columns: 3fr 3fr;
    align-items: center;
    position: relative;
    margin-bottom: 16px;

  &__input {
    border: none;
    background-color: var(--background-layer-tetriary);
    color: var(--text-color-additional);
    font-size: 16px;
    outline: none;
    max-width: 90px;
    font-family: var(--main-font);
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px;

    &:focus {
      color: var(--text-color-quaternary);
      cursor: default;
    }

    &::placeholder {
      color: var(--text-color-additional);
    }

   &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--background-layer-tetriary) inset;
      box-shadow: 0 0 0 1000px var(--background-layer-tetriary) inset;
      -webkit-text-fill-color: var(--text-color-additional);
    }
  }

  .c-icon-clock {
    fill: var(--background-icon-color-blue);
    right: 1px;
    position: absolute;
  }

  &:after{
    content: attr(data-title);
    position: absolute;
    top: 20px;
    left: 0px;
    opacity: 0;
    transition: 0.5s linear;
    color: var(--confirm-color);
  }
  
  &:hover:after{
    opacity: 1;
  }
}
