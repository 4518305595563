@import '../../assets/styles/mixins';

.c-task-assigned-user {
  margin-bottom: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    &__title {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;

    .c-icon-bin {
      fill: var(--color-coral);
      height: 16px;
      display: none;
    }

    &:hover {
      .c-icon-bin {
        display: block;
      }
    }
  }

  &__empty-state {
    height: 34px;
    color: var(--text-color-tetriary);
    display: flex;
    align-items: center;
  }

  &__board-team {
    &__title {
      margin-bottom: 8px;
    }

    &__list {
      height: 210px;
      overflow-y: auto;
      border: 1px solid var(--border-color-main);
      @include custom-scroll-bar();

      &__member {
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .c-user-field {
          width: calc(100% - 16px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &:hover {
          background-color: var(--background-layer-additional);
          transition: all 0.3s ease;
        }

        .c-icon-select {
          fill: var(--background-icon-color-gray);
        }
      }
    }

    &__message {
      height: 208px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-silver-light);
    }
  }

  &.mobile {
    .c-task-assigned-user__header {
      justify-content: start;

      &__title {
        margin-right: 16px;
      }

      .c-icon-change {
        fill: var(--background-icon-color-blue);
      }
    }

    .c-task-assigned-user__user {
      .c-icon-bin {
        display: block;
      }
    }
  }
}

.c-modal.mobile.modal-assigned-user {
  .c-modal__content {
    margin-bottom: 34px;
  }

  .c-modal__footer {
    flex-direction: column;

    & .c-button:first-child {
      margin-bottom: 16px;
    }
  }
}