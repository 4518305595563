.c-user-edit-profile {
  display: flex;
  flex-direction: row;
  max-width: 1100px;
  margin-bottom: 32px;
  justify-content: space-between;

  &__info {
    display: none;
  }

  &__left-side {
    display: flex;
    flex-direction: row;
    width: 48%;

    &.hidden {
      display: none;
    }

    .c-input {
      width: 280px;
    }

    .c-textarea {
      width: 280px;
    }
  }

  &__right-side {
    display: flex;
    flex-direction: row;
    width: 48%;

    .c-select-control {
      width: 280px;
    }

    &.hidden {
      display: none;
    }
  }
  &__avatar {
    margin-top: 10px 0px 0px 8px;
    width: 94px;
  }

  &__theme {
    max-width: 360px;
    width: 100%;
  }

  &__lang {
    margin-top: -8px;
    max-width: 360px;
    width: 100%;
  }

  &__form-wrapper {
    margin-bottom: 40px;
    width: 75%;

    &.hidden {
      display: none;
    }

    .c-input {
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }

  &__input-file {
    display: none;
  }

  .c-icon-edit {
    stroke: var(--background-icon-color-blue);
    position: relative;
    top: -68px;
    left: 48px;
    cursor: pointer;
  }

  .c-icon-bin {
    fill: var(--danger-color);
    position: relative;
    top: -7px;
    left: 26px;
    cursor: pointer;
  }
}

@media screen and (max-width: 880px) {
  .c-user-edit-profile {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  .c-user-edit-profile {
    
    &__data {
      display: flex;

      .c-user-avatar {
        width: 76px;
        height: 80px;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      overflow-wrap: anywhere;
      width: 100%;
    }

    &__horizontal-line {
      margin: 0px 24px;
    }

    &__left-side {
      flex-direction: column;
      width: 100%;
      row-gap: 12px;
    }

    &__form-wrapper {
      width: 100%;
      margin-bottom: 32px;
      .c-heading.secondary {
        font-size: 32px;
      }

      .c-input {
        width: 100%;
        &__input {
          background-color: var(--background-layer-quaternary);
        }
      }

      .c-textarea {
        width: 100%;
        &__textarea {
          background-color: var(--background-layer-quaternary);
        }
      }
    }

    &__form-actions {
      width: 100%;

      button {
        width: inherit;
      }
    }

    &__right-side {
      width: 100%;
    }

    &__appearance {
      width: 100%;
    }

    &__theme {
      width: 100%;
      max-width: none;
    }

    &__lang {
      width: 100%;
      max-width: none;
    }

    .c-select-control {
      width: 100%;
      &__select-text {
        border: none;
      }
    }

    .c-icon-edit {
      top: 0px;
      left: initial;
      right: -20px;
      position: absolute;
    }

    .c-icon-bin {
      top: initial;
      left: initial;
      bottom: 0px;
      right: -20px;
      position: absolute;
    }

    &__data {
      display: flex;
      flex-direction: row;
    }

    &__max-size-warning {
      display: none;
    }

    &__avatar {
      position: relative;
      height: fit-content;
    }

    &__avatar-wrapper {
      height: 100%;
      display: flex;
      align-items: flex-end;
    }
  }
}
