.c-input {
  display: block;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 4px;
    height: calc(100% - 24px);
    background-color: var(--mark-color-blue);
  }

  &:focus-within {
    &.disabled::before {
      background-color: var(--mark-color-blue);
    }

    &.valid::before {
      background-color: var(--mark-color-blue);
    }

    &.error::before {
      background-color: var(--mark-color-blue);
    }
  }

  &.hidden {
    display: none;
  }

  &.reset {
    .c-input__input {
      padding-right: 34px;
    }
  }

  &.label {
    &::before {
      height: 64px;
    }
  }

  &_required {
    .c-input__label-text {
      &::after {
        position: absolute;
        top: -6px;
        color: var(--danger-color);
        font-weight: 900;
        content: "*";
      }
    }
  }

  &.disabled {
    &::before {
      background-color: var(--mark-color-graphite-light);
    }
  }

  &.valid {
    &::before {
      background-color: var(--mark-color-aqua);
    }
  }

  &.error {
    &::before {
      background-color: var(--mark-color-coral);
    }

    .c-input__bottom-text {
      color: var(--danger-color);
    }
  }

  &__label-text {
    padding-left: 12px;
    height: 1.5em;
  }

  &__input {
    font-family: inherit;
    font-size: 16px;
    border-radius: 0px 4px 4px 0px;
    padding: 8px 8px 8px 12px;
    width: 100%;
    max-width: 100%;
    height: 40px;
    border: none;
    appearance: none;
    background-color: var(--background-layer-tetriary);
    color: var(--text-color-tetriary);

    &_date[type="date"] {
      padding-left: 38px;
      color: transparent;
      user-select: none;
      font-size: 0;
      user-select: none;
      pointer-events: none;

      &::-webkit-calendar-picker-indicator {
        font-size: 23px;
        position: relative;
        right: 100%;
        z-index: 2;
        opacity: 0;
        cursor: pointer;
      }

      &::-webkit-datetime-edit {
        visibility: hidden;
      }
    }

    &:focus {
      box-shadow: 0px -2px 0px 0px var(--mark-color-blue) inset;
      border-radius: 0px 4px 0px 0px;
      outline: 0px;
      user-select: none;
      color: var(--text-color-primary);
    }

    &::placeholder {
      color: var(--text-color-tetriary);
    }
  }

  &__date-elements {
    display: flex;
    position: absolute;
    z-index: 1;
    left: 12px;
    bottom: 30px;

    .c-input__date-value {
      color: var(--text-color-tetriary);
    }
  }

  &__bottom-text {
    color: var(--confirm-color);
    padding-left: 12px;
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
  }

  .c-icon-calendar {
    fill: var(--background-icon-color-blue);
    margin-right: 8px;
  }

  .c-icon-block-round,
  .c-icon-cross-round {
    fill: var(--background-icon-color-gray);
    position: absolute;
    height: 20px;
    top: 34px;
    right: 4px;
  }

  .c-icon-open-eye,
  .c-icon-close-eye {
    position: absolute;
    top: 50%;
    right: -32px;
    transform: translateY(-50%);
    cursor: pointer;
    fill: var(--background-icon-color-gray);
  }
}

// @media screen and (max-width: 767px) {
//   .c-input {
//     &__input {
//       background-color: var(--background-layer-quaternary);
//     }
//   }
// }
