.c-board-panel-list {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .c-board-panel-list {
    display: flex;
    flex-direction: column;
    
  }
}
