.c-board-actions-panel {
  &__team {
    margin-bottom: 14px;
    overflow: hidden;
  }

  &__team-title {
    font-size: inherit;

    .c-icon-plus {
      display: none;
    }
  }

  .action-subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
  }

  &__wrapper-users {
    overflow-y: auto;
    height: 100%;
  }

  &__user {
    overflow-y: auto;
    margin-bottom: 14px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    line-height: 1.2;

    &:last-child {
      margin-bottom: 0px;
    }

    .c-icon-cross-round {
      fill: var(--color-coral);
      visibility: hidden;
    }

    &:hover {
      .c-icon-cross-round {
        visibility: visible;
      }
    }

    .c-user-field {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.1;
    }
  }
  &__delete-user-description {
    text-align: center;
  }
}


@media screen and (max-width:767px) {
  .c-board-actions-panel {
    &__team {
      margin-bottom: 10px;
    }

    &__user {
      .c-icon-cross-round {
        visibility: visible;
      }
    }

    &__team-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .c-icon.c-icon-plus {
        display: block;
        fill: var(--color-white);
        stroke: var(--color-white);
      }
    }
  }
}
