@import '../../assets/styles/mixins';

.c-task-mobile {
  width: 100%;
  position: relative;
  flex-grow: 1;

  &__back-page {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    flex-wrap: nowrap;
    white-space: nowrap;

    & .c-paragraph {
      white-space: nowrap;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__board-name {
      text-decoration: underline;
    }
  }

  &__header {
    position: relative;
    padding-left: 16px;
    margin-bottom: 8px;

    .c-task-header {
      margin-bottom: 0px;
    }
  }

  &__status-list {
    display: flex;
    flex-direction: row;
    gap: 2px;
    list-style: none;
    margin-bottom: 8px;
    overflow-x: scroll;
    @include custom-scroll-bar();

    &__status {
      width: fit-content;
      color: var(--text-color-secondary);
      background-color: var(--background-layer-additional);
      padding: 8px 12px;
      text-wrap: nowrap;
      flex-grow: 1;
      text-align: center;

      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }

      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }

      &.active {
        background-color: var(--color-azure);
      }
    }
  }

  &__section {
    width: 100%;
    margin-bottom: 8px;
    background-color: var(--background-layer-quaternary);
    border: 1px solid var(--border-color-main);
    border-radius: 4px;
    padding: 8px 12px;
    position: relative;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 500;

      &.open {
        margin-bottom: 16px;
      }
    }

    & .c-task-estimation-time {
      &__input {
        background-color: var(--background-layer-quaternary);

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px var(--background-layer-quaternary) inset;
          box-shadow: 0 0 0 1000px var(--background-layer-quaternary) inset;
        }
      }
    }

    &:nth-last-child(2) {
      margin-bottom: 38px;
    }

    & .c-tag-list {
      &__header {
        background-color: var(--background-layer-quaternary);
      }

      .c-task__title {
        font-size: 24px;
        font-weight: 500;
      }
    }
  }

  & .c-task-actions {
    bottom: 0px;
    width: 100%;
  }

  .c-icon-chevron-up, .c-icon-chevron-down {
    fill: var(--background-icon-color-primary);
  }
}