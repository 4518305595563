.c-priority-icon {
  line-height: 1;
  
  &__high {
    fill: var(--priority-color-high);
  }

  &__medium {
    fill: var(--priority-color-medium);
  }

  &__low {
    fill: var(--priority-color-low);
  }
}
