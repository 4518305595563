.c-task-card-description {
  margin-bottom: 10px;
  line-height: 1.5;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--text-color-primary);
  
  a {
    color: var(--text-color-quaternary);
    text-decoration: none;
    cursor: inherit;
  }
}
