.c-paragraph {
  color: inherit;

  &.extra-large {
    font-size: 24px;
  }

  &.large {
    font-size: 20px;
  }

  &.primary {
    font-size: 16px;
  }

  &.secondary {
    font-size: 14px;
    line-height: 1.25;
  }
}
