@import '../../assets/styles/mixins';

.c-status-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: hidden;
  overflow-x: auto;
  padding-top: 24px;
  padding-bottom: 4px;
  @include custom-scroll-bar();
}


@media screen and (max-width:767px) {
  .c-status-list {
    flex-direction: column;
    row-gap: 8px;
    overflow-x: hidden;
    padding-top: 16px;
    padding-bottom: 0px;

    .c-status-card {
      width: 100%;
    }

    .c-board {
      &__task {
        margin-bottom: 4px;

        &:last-of-type {
          margin-bottom: 12px;
        }
        
        .c-task-card {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &-header {
            -webkit-line-clamp: 1;
            margin-bottom: initial;
          }

          &-description {
            display: none;
          }

          &-footer {
            display: block;
            width: auto;

            .c-priority-icon {
              display: none;
            }

            &__time {
              display: none;
            }

            &__date {
              display: none;
            }

            span[title=Priority] {
              display: none;
            }
          }
        }
      }
    }
  }
}
