.c-user-list {
  display: inline-flex;
  cursor: pointer;

  &__user {
    margin-right: -12px;
    transition: all 0.5s;

    &:last-child {
      &:hover {
        transform: translate(0%, 0%);
      }
    }

    &:hover {
      transform: translate(-12%, 0%);
      transition: all 0.5s;
    }
  }

  .c-icon-remove-user {
    position: relative;
    display: flex;
    width: 34px;
    height: 34px;
    background-color: var(--priority-color-low);
    border-radius: 50%;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow:inset rgba(0, 0, 0, 0.3) 0px 0px 2px, inset rgba(0, 0, 0, 0.3) 0px 0px 0px 2px;
      top: 0;
      left: 0;
    }

    &.c-icon_lg {
      width: 56px;
      height: 56px;
      padding: 10px;
    }
  
    &.c-icon_md {
      width: 44px;
      height: 44px;
      padding: 8px;
    }
  
    &.c-icon_sm {
      width: 34px;
      height: 34px;
      padding: 6px;
    }
  
    &.c-icon_xs {
      width: 22px;
      height: 22px;
      padding: 4px;
    }
  }
  .c-icon-plus {
    display: none;
  }

  &_lg {
    .c-user-list__user {
      margin-right: -14px;
    }
  }

  &_md {
    .c-user-list__user {
      margin-right: -12px;
    }
  }

  &_sm {
    .c-user-list__user {
      margin-right: -10px;
    }
  }

  &_xs {
    .c-user-list__user {
      margin-right: -8px;
    }
  }
}



@media screen and (max-width:767px) {
  .c-user-list {
    &__user {
      margin-right: -8px;
      &:hover {
        &:first-child {
          transform: initial;
        }
      }
    }

    .c-icon-plus {
      display: grid;
      align-items: center;
      stroke-width: 1.2px;
      margin-left: 20px;
      stroke: var(--color-graphite-light);
    }

    .c-icon-remove-user.c-icon_sm {
      padding: 8px;
    }
  } 
}
