.c-auth-form {
  border-radius: 4px;
  border: 1px solid var(--border-color-main);
  background-color: var(--background-layer-secondary);
  position: relative;
  width: 400px;
  min-height: 388px;

  &__container {
    width: 400px;
  }

  &__view {
    padding: 24px;

    .c-paragraph {
      margin-bottom: 24px;
      font-weight: 500;
    }

    .c-heading {
      text-align: center;
      font-weight: 700;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 370px;
  }

  &__restore {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;

    .c-icon + .c-auth-form__restore-prompt {
      margin-left: 8px;
    }
  }

  &__restore-label {
    text-align: center;
    margin-bottom: 20px;

    span {
      display: block;
    }
  }

  &__restore-prompt {
    color: var(--info-color);
    font-size: 12px;
    line-height: 1.5;
  }

  &__link {
    text-decoration: none;
    color: var(--info-color);
  }

  &__restore-btn {
    .c-button {
      margin-bottom: 16px;
    }
  }

  .c-button {
    width: 100%;
  }

  &__clarification {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    margin-bottom: 24px;
  }

  &__agreement {
    padding-top: 12px;
    margin-bottom: 24px;
  }

  .c-timer {
    margin-bottom: 20px;
  }

  .c-icon-open-eye,
  .c-icon-close-eye {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .c-icon-question-circle {
    width: 18px;
    height: 18px;
    fill: var(--background-icon-color-blue);
  }
}

.c-input__input {
  padding-right: 36px;
}

html[data-theme='dark'] {
  .c-auth-form {
    &__social-buttons {
      .c-button {
        color: var(--color-white)
      }
      .c-paragraph {
        color: var(--color-white)
      }
    }
  }
}

@media screen and (max-width: 767px) {
  html[data-theme="dark"] {
    .c-auth-form {

      &__suggestion {
        &__text {
          color: var(--color-silver);
        }
        &__link {
          color: var(--color-blue);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .c-auth-form {
    height: 100%;
    width: 100%;
    padding-top: 24px;
    border: initial;
    border-radius: initial;
    .c-input {
      &__input {
        background-color: var(--color-silver-light);
        &::placeholder {
          color: var(--color-gray);
        }
      }
    }

    &__container {
      height: 100%;
      width: auto;
    }
    &__nav {
      display: none;
    }

    &__agreement {
      padding-top: 0px;
    }

    &__clarification {
      padding-top: 0px;
    }

    &__suggestion {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      column-gap: 20px;

      &__text {
        font-size: 12px;
        color: var(--color-gray);
      }
    }

    &__password_field {
      position: relative;
    }

    &__restore {
      white-space: nowrap;
      font-size: 14px;
      right: 0px;
      color: var(--color-gray);
      text-decoration: underline;
      width: auto;
    }

    .c-logo {
      &__wrapper {
        align-items: center;
        justify-content: center;
      }
      &__name {
        padding: 0 8px;
      }
    }
    &__form {
      .c-button {
        margin-bottom: 12px;
        background-color: var(--color-blue);
        color: var(--color-white);
        border: 1px solid var(--color-silver);
        border-radius: 8px;
        &:disabled {
          background-color: var(--color-silver-light);
          color: var(--color-blue);
        }
        span {
          text-transform: uppercase;
        }
      }
    }

    .c-icon-cross-round {
      fill: var(--color-graphite);
    }

    .c-link-url {
      font-size: 12px;
      text-decoration: underline;
    }
  }
}
