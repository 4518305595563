.c-board-list-null {
  &__content {
    padding: 32px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: 24px;
    line-height: 36px;
  }

  &__message {
    font-size: 36px;
    line-height: 54px;
    font-weight: 700;
    margin-bottom: 16px;
  }
}
