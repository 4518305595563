.c-sort-board {
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 30px;
  line-height: 1;
  padding: 4px 8px 4px 16px;
  color: var(--info-color);

  &.ascending {
    .c-icon-arrow {
      fill: var(--color-brand);
      transition: 0.2s;

      &:last-child {
        fill: var(--background-icon-color-blue);
      }
    }
  }

  &.descending {
    .c-icon-arrow:last-child {
      fill: var(--color-brand);
      transition: 0.2s;
    }
  }

  &__title {
    text-wrap: nowrap;
  }

  &__list-sort-types {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: inherit;
    padding: 0px 8px 0px 6px;
  }

  .c-icon-arrow {
      width: 12px;
      transition-duration: 0.2s;
      fill: var(--background-icon-color-blue);

      &:hover {
        transform: scale(1.2);
        fill: var(--background-icon-color-gray);
      }

      &:last-child {
        transform: rotate(180deg);

        &:hover {
          transform: rotate(180deg) scale(1.2);
          fill: var(--background-icon-color-gray);
        }

        svg {
          margin-top: 4px;
        }
      }
  }

  .c-sort-type {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px; 
    height: 20px;
    margin-right: 10px;
    padding: 0px 4px;
    text-wrap: nowrap;
    transition-duration: 0.2s;

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      color: var(--text-color-tetriary);
    }

    &_selected {
      color: var(--color-brand);
    }
  }
}

@media screen and (max-width: 767px){
  .c-sort-board {
    &.descending {
      display: none;
    }
  }
}
