@mixin custom-scroll-bar() {
    &::-webkit-scrollbar {
        border-radius: 10px;
        height: 10px;
        width: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
        background: var(--color-blue);
        border-radius: 10px;

        &:hover {
            background-color: #3682c7;
        }
  
        &:active {
            background-color: #195184;
        }
    }
  
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
}

@mixin background-transparent-color($color, $alpha) {
  $rgba: rgba($color, $alpha);
  background-color: $rgba;
}
