.c-header-layout {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: var(--color-graphite);
  color: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 24px;

  &__title {
    height: 50px;
  }

  &__user {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-gap: 16px;

    .c-user-field__user-name {
      font-size: 22px;
    }
  }

  .c-icon-burger {
    display: none;
    fill: var(--color-brand);
  }
}

@media screen and (max-width: 768px) {
  .c-header-layout {
    padding: 9px 16px;

    &__user {
      grid-template-columns: auto;
      grid-gap: 0px;

      .c-user-field {
        display: none;
      }

      .c-button {
        display: none;
      }

      .c-icon-burger {
        display: block;
      }
    }
  }
}
