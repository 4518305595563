.c-board-actions-panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .c-icon-edit {
    stroke: var(--background-icon-color-blue);
  }

  .c-button {
    width: 100%;
  }

  // .c-button.confirm-inverse {
  //   .c-button__icon {
  //     display: none;
  //   }
  // }

  .c-color-palette {
    position: absolute;
    z-index: 2;
  }

  &__wrapper {
    font-family: inherit;
  }

  &__actions-header {
    font-size: inherit;

    .c-icon-chevron-down {
      transform: rotate(270deg);
      fill: var(--background-icon-color-gray);
      padding-top: 8px;
    }
  }

  .action-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    line-height: 36px;
    display: inline-block;
  }

  &__wrapper-team-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width:767px) {
  .c-board-actions-panel {
    padding: 12px 16px;
    background-color: var(--color-graphite-light);
    color: var(--color-white);
    font-size: 16px;
    width: 100%;

    &__wrapper {
      .c-button {
        display: none;
      }
    }

    &__actions-header.action-title {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    &__title {
      font-weight: 500;
      padding-left: 16px;
    }

    .c-icon-chevron-down {
      padding-top: 0px;
      fill: var(--color-white);
    }

    .c-icon-edit {
      stroke: var(--color-white);
    }
  }
}
