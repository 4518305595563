.c-user-tasks {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 158px;

  .c-table {
    .c-row__cell {
      .high-value {
        color: var(--danger-color);
      }
    }

    .c-row__cell.c-0:hover,
    .c-row__cell.c-6:hover{
      color: var(--text-color-quaternary);
    }
  }

  .c-progress-icons {
    display: flex;
    max-width: 172px;
    
    span {
      display: flex;
      margin-right: 4px;
      width: 12px;
      max-width: 12px;
      min-width: 6px;
      height: 32px;
      border-radius: 2px;

      &:last-of-type {
        margin-right: 0px;
      }
  
      &.aqua {
        background-color: var(--color-aqua);
      }
  
      &.orange {
        background-color: var(--color-orange);
      }
  
      &.red {
        background-color: var(--color-red);
      }
  
      &.gray{
        background-color: var(--color-silver);
      }
    }
  }
}
