@import '../../assets/styles/mixins';

.c-todo-list {

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--background-layer-tetriary);
    padding: 8px;
  }

  &__summary-data {
    font-size: 14px;
    line-height: 1.5;
    padding: 8px;
    display: grid;
    grid-template-columns: 80px 2fr 1fr;
    align-items: center;
    color: var(--text-color-tetriary);

    &__view {
      color: var(--info-color);
      cursor: pointer;
      justify-self: end;

      &:hover {
        color: var(--text-color-tetriary);
        transition: all ease 0.3s;
      }
    }
  }

  &__list {
    max-height: 552px;
    overflow-y: scroll;
    @include custom-scroll-bar();

    .c-task_full-screen,
    .c-task-mobile & {
      max-height: none;
    }

    &__item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 24px;
      padding: 4px 8px;
      line-height: 1;

      &__checkbox {
        margin: 0px 8px 0px 0px;
        width: 16px;
      }

      .c-todo-item {
        width: calc(100% - 48px - 24px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__options {
        display: none;
        justify-content: space-between;
        margin-left: 12px;

        .c-icon-edit {
          stroke: var(--background-icon-color-blue);
          margin-right: 4px;
        }

        .c-icon-bin {
          fill: var(--color-coral);
        }
      }

      &:hover {
        .c-todo-list__list__item__options {
          display: block;
        }
      }

      &:has(.c-todo-list__list__item__edit__field) {
        .c-todo-list__list__item__checkbox {
          display: none;
        }
      }

      &__edit__field {
        border: 1px solid var(--border-color-main);
        background-color: var(--background-layer-tetriary);
        padding: 4px;
        width: 100%;
        height: 24px;
        border-radius: 4px;
        outline: none;
        transition: border-color 0.3s ease-in-out;
        color: var(--text-color-primary);
        font-size: 16px;

        &:focus {
          border-color: var(--info-color);
        }
      }
    }
  }

  &__input {
    display: flex;
    flex-direction: row;
    padding-left: 8px;
    justify-content: space-between;
    width: calc(100% - 150px);
    margin-bottom: 8px;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeInUp 0.5s ease-out forwards;

    @keyframes fadeInUp {
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &__field {
      height: 24px;
      outline: none;
      border: none;
      border-bottom: 1px solid var(--text-color-tetriary);
      width: calc(100% - 40px - 4px);
      padding: 0px;
      background-color: inherit;
      color: var(--text-color-primary);
      
      &::placeholder {
        color: var(--text-color-tetriary);
      }
    }

    &__options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 36px;

      .c-icon-save {
        fill: var(--color-aqua);
      }

      .c-icon-cross-round {
        fill: var(--color-coral);
      }
    }
  }

  &.visible {
    background: var(--background-layer-secondary);
    box-shadow: var(--box-shadow-primary);
    border-radius: 4px;
    border: 1px solid var(--border-color-main);
    height: calc(100% - 16px - 16px - 40px);
    width: calc(100% - 332px);
    position: absolute;
    top: 52px;
  }

  &.mobile {
    .c-todo-list__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      background-color: inherit;
      padding: 0px;

      &__title {
        display: flex;
        justify-content:flex-start;
        align-items: center;
        gap: 16px;
      }
  
      .c-task__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
      }
  
      .c-icon-plus {
        stroke: var(--background-icon-color-gray);
        cursor: pointer;
      }
    }

    .c-todo-list__summary-data {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 24px;
      padding-left: 0px;
  
      &__title {
        color: var(--text-color-tetriary);
      }
  
      &__total {
        font-size: 14px;
        padding: 6px 12px;
        background-color: var(--color-mint-light);
        border-radius: 4px;
        color: var(--color-graphite);
      }
    }

    .c-todo-list__list {
      overflow-y:hidden;

      &__item {
        padding: 0px;
        margin-bottom: 8px;
        line-height: 1.5;
        align-items: normal;

        &:last-child {
          margin-bottom: 0px;
        }

        &__edit__field {
          background-color: inherit;
        }

        &__options {
          display: block;
        }
      }
    }

    .c-todo-list__input {
      width: 100%;
      margin-top: 16px;
      padding: 0px;
    }
  }

  #todo-tooltip {
    z-index: 502;
  }
}