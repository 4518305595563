.c-logo {
  font-weight: 400;
  font-size: 36px;
  line-height: 1.5em;
  color: #f6be00;
  cursor: pointer;

  &__wrapper {
    display: flex;
    flex-direction: row;
  }

  &__icon {
    display: flex;
    align-self: center;
    overflow: hidden;

    .right-side-image & {
      order: 2;
    }

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  &__name {
    padding: 0px 16px;
    display: flex;
    align-items: center;
    line-height: 1;
  }

  &_md {
    font-size: 36px;

    .c-logo__icon {
      height: 44px;
    }
  }
  
  &_sm {
    font-size: 24px;

    .c-logo__icon {
      height: 28px;
    }
  }
}

