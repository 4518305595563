.c-toaster {
  position: fixed;
  z-index: 999999;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 700;

  &__toast {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 0 0 10px var(--color-gray);
    border-radius: 6px;
    margin-bottom: 14px;
    opacity: 0.9;
    height: 40px;
    min-width: 206px;
    padding: 0px 10px;
    animation: toast-in-top .5s ease-in;

    &:hover {
      box-shadow: 0 0 12px var(--color-white);
      opacity: 1;
      cursor: pointer
    }

    &_success {
      background-color: var(--confirm-color);
    }

    &_error {
      background-color: var(--danger-color);
    }
  }

  &__title {
    font-size: inherit;
  }
}

@keyframes toast-in-top {
  from {
    transform: translateY(calc(-100% - 126px - var(--toaster-height)));
  }
  to {
    transform: translateY(0%);
  }
}
