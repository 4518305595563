  .c-board-filter {
  padding-top: 12px;
  display: flex;
  font-size: inherit;
  flex-direction: row;
  flex-wrap: wrap;

  &.empty-filter {
    display: none;
  }

  &__title {
    margin-right: 18px;
    margin-top: 12px;

  }
  &__priority {
    text-align: center;
    padding-right: 16px;
    margin-top: 12px;

    &__btn {
      text-align: center;
      margin-left: 8px;
      padding-right: 8px;
      border-radius: 12px;
      line-height: 24px;
      border: none;
      background-color: var(--background-layer-additional);
      color: var(--text-color-secondary);
      text-decoration: none;
      width: 94px;
      cursor: pointer;

      &__low {
        color: var(--color-white);
        margin-left: 8px;
        border-radius: 12px;
        line-height: 24px;
        width: 94px;
        border: none;
        background-color: var(--color-gray-light);
      }
      &__low:before {
        content: '✓';
        margin-right: 8px;
      }
      &__medium {
        color: var(--color-white);
        margin-left: 8px;
        border-radius: 12px;
        line-height: 24px;
        width: 94px;
        border: none;
        background-color: var(--priority-color-medium);
      }
      &__medium:before {
        content: '✓';
        margin-right: 8px;
      }
  
      &__high {
        color: var(--color-white);
        margin-left: 8px;
        border-radius: 12px;
        line-height: 24px;
        border: none;
        width: 94px;
        background-color: var(--color-coral); 
      }
      &__high:before {
        content: '✓';
        margin-right: 8px;
      }
    }
    &__btn:before {
      content: "✖ ";
      margin-right: 4px;
  
    }
  }
  
  &__select-user {
    display: flex;
    align-items: center;
    background-color: var(--color-lime);
    border-radius: 16px;
    padding: 0px 12px 0px 8px;
    height: 24px;
    margin-right: 16px;
    margin-top: 12px;

  
    &:last-child {
      margin-right: 0px;
    }
  
    .c-icon-plus {
      stroke: var(--text-color-secondary);
      transform: rotate(45deg);
      width: 12px;
      position: relative;
      bottom: -1px;
      
      svg {
        transform: skew(-6deg,-6deg);
      }
    }

    &_no-assigned {
      background-color: var(--color-gray-light);
    }
  }
  
  &__select-user-name {
    margin-left: 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--text-color-primary);
  }

  .c-button {
    display: inline-block;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    align-self: flex-start;
    height: 24px;
    line-height: 14px;
    color: var(--color-white);
    border-radius: 16px;
    background-color: var(--color-blue);
    border: none;
    padding: 0px 16px;
    margin-top: 12px;
    cursor: pointer;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    
    .c-tag {
      margin: 12px 2px 2px;
    }
  }

  &__input_tags {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    flex-wrap: wrap;
    position: relative;
    gap: 4px;
  }

    .c-board-filter__input_tag {
      position: relative;

      .c-tag {
        padding: 0px 12px;
      }

      .c-icon-cross-round {
        display: none;
        position: absolute;
        fill: var(--background-icon-color-blue);
        right: 1px;
        bottom: 16px;
        width: 10px;
      }
              
      &:hover {
        .c-icon-cross-round {
          display: inline-block;
        }
      }
  
    }

  &__add_tag {
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
    height: 24px;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-white);
    border-radius: 16px;
    background-color: var(--color-blue);
    border: none;
    padding: 0px 16px;
    margin-top: 12px;
    cursor: pointer;
    stroke: var(--color-white);
    gap: 8px;
    margin-right: 8px;
  }

}