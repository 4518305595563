.c-settings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1100px;
  flex-wrap: wrap;
  margin-bottom: 32px;

  &__security {
    width: 48%;
    padding-left: 94px;

    &.restore {
      padding-left: 0%;
    }

    .c-input {
      width: 280px;

      &:last-of-type {
        margin-bottom: 16px;
      }
    }
  }

  &__remove-user {
    width: 48%;

    &.hidden {
      display: none;
    }
  }
}

@media screen and (max-width: 880px) {
  .c-settings {
    column-gap: 16px;
    width: 100%;

    &__security {
      padding-left: 0px;
      padding-bottom: 32px;
      width: initial;
      flex: auto;
    }

    &__remove-user {
      width: initial;
      flex: auto;
    }

    .c-input {
      width: 87%;
      &__input {
        background-color: var(--background-layer-quaternary);
      }
    }

    .c-icon-open-eye {
      right: -38px;
    }

    .c-icon-close-eye {
      right: -38px;
    }

    .c-button {
      width: 100%;
    }

    .c-heading {
      white-space: nowrap;
    }
  }
}
