@import '../../assets/styles/mixins';

.c-task-board-select {
  margin-bottom: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    &__title {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__board-current {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--text-color-tetriary);
  }

  &__boards {
    &__title {
      margin-bottom: 8px;
    }

    &__list {
      height: 200px;
      overflow-y: auto;
      border: 1px solid var(--border-color-main);
      @include custom-scroll-bar();

      &__member {
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--text-color-tetriary);

        &__name {
          width: calc(100% - 16px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &:hover {
          background-color: var(--background-layer-additional);
          transition: all 0.3s ease;
        }

        .c-icon-select {
          fill: var(--background-icon-color-gray);
        }
      }
    }

    &__message {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--background-layer-additional);
    }
  }
}


.c-modal.mobile.modal-board-select {
  .c-modal__footer {
    flex-direction: column;

    & .c-button:first-child {
      margin-bottom: 16px;
    }
  }
}
