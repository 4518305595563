.c-color-mark-bar {
  position: absolute;

  &_top {
    width: 100%;
    top: 0px;
    left: 0px;
    border-top: 4px solid transparent;
    border-radius: 4px 4px 0px 0px;
  }

  &_left {
    height: 100%;
    top: 0px;
    left: 0px;
    border-left: 4px solid transparent;
    border-radius: 4px 0px 0px 4px;
  }

  &_right {
    height: 100%;
    top: 0px;
    right: 0px;
    border-right: 4px solid transparent;
    border-radius: 0px 4px 4px 0px;
  }

  &_bottom {
    width: 100%;
    bottom: 0px;
    left: 0px;
    border-bottom: 4px solid transparent;
    border-radius:0px 0px 4px 4px;
  }
}
