.c-task-color-mark {
  position: relative;
  margin-bottom: 16px;

  & .c-task__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    & .c-icon-color-pattern {
      fill: var(--background-icon-color-blue);
    }
  }

  &__color {
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
  }

  & .c-color-palette {
    position: absolute;
    top: 24px;
    right: 0px;
    z-index: 1002;
  }
}
