.c-auth-form-greetings {
  display: block;
  margin-bottom: 40px;
}

.c-auth-form-greetings_text {
  text-align: center;

  .c-heading {
    text-align: center;
    color: var(--color-graphite-light);
    display: block;
  }

  .c-paragraph {
    color: var(--color-graphite-light);
  }
}

html[data-theme="dark"] {
  .c-auth-form-greetings_text {
    .c-heading {
      color: var(--color-brand);
    }
    .c-paragraph {
      color: var(--color-silver);
    }
  }
}
