.c-board-card__wrapper {
  width: 220px;
  height: 160px;
  background: var(--background-layer-tetriary);
  border: 1px solid var(--border-color-main);
  border-radius: 4px;
  box-shadow: var(--box-shadow-primary);
  position: relative;
  margin: 0px 10px 20px;

  &.draggable {
    border: 2px solid var(--color-lime);
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    z-index: 5000;

    .c-board-card {
      cursor: grabbing;
      user-select: none;
    }

    .c-color-mark-bar_bottom {
      border-radius: 0px 0px 2px 2px;
    }
  }

  &.empty-card {
    -ms-touch-action: none;
    pointer-events: none;
    touch-action: none;
    user-select: none;
    visibility: hidden;
  }
}

.c-board-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px 8px;
  cursor: pointer;
  
  &__header {
    display: grid;
    grid-template-columns: auto 20px;
    align-items: center;
    min-height: 36px;
    margin-bottom: 4px;
  }

  &__icons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .c-icon {
    fill: var(--background-icon-color-gray);
  }

  &.owner {
    .c-icon-users {
      fill: var(--color-aqua);
    }
  }
  
  .c-icon-star {
    fill: var(--color-brand);
  }

  &__title {
    font-size: 24px;
    line-height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__description {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: var(--text-color-tetriary);
    height: 100%;
    max-height: 80px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color-tetriary);
  }
}

@media screen and (max-width: 767px) {
  .c-board-card {
    width: 220px;

    &__description {
      white-space: normal;
      word-wrap: break-word;
    }
  }
}
