.c-promo-contact-form {
  background: var(--background-layer-tetriary);
  display: flex;
  justify-content: center;

  .c-promo__container {
    display: flex;
    flex-direction: row;
    gap: 48px;
  }

  &__promo-block {
    background: var(--background-layer-quaternary);
    border: 1px solid var(--border-color-main);
    border-radius: 4px;
    padding: 24px;
    // width: calc(45% - 24px);
    line-height: 1;
    align-self: start;
    margin-top: 24px;
    width: 428px;

    &__title {
      font-size: 28px;
      margin-bottom: 24px;
    }

    &__description {
      font-size: 22px;
      margin-bottom: 24px;
      line-height: 1.5;
    }

    .c-button.primary {
      white-space: nowrap;
    }
  }  

  &__form {
    width: calc(100% - 24px - 428px);

    &__title {
      font-size: 54px;
      margin-bottom: 24px;
    }

    &__description {
      font-size: 22px;
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 1050px) {
  .c-promo-contact-form {
    .c-promo__container {
      flex-direction: column;
      width: 100%;
    } 
  
    &__form {
      width: 100%;
      max-width: 800px;
    }
  }
}


@media (max-width:768px){
  .c-promo-contact-form__promo-block{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    width: 100%;
    background: var(--color-silver);

    
    &__title{
      font-size: 2.3em;
      font-weight: 600;
      margin-bottom: 15px;
    }
    
    &__description{
      margin: 0;
      font-size: 20px;
      
      .c-promo__logo{
        color: var(--text-color-primary);
      }
    }
    
    &__description :last-child{
      font-weight: 500;
    }
    
    .c-button.primary{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      font-size: 0.8em;
    }
    
    .c-promo-contact-form__promo-block__description, .c-button.primary{
      order: -1;
    }
  }
  
  html[data-theme='dark'] {
    .c-promo-contact-form__promo-block{
      background: var(--color-graphite-light);
    }
  }
  
  .c-promo-contact-form__form{
    &__title{
      font-size: 35px;
      font-weight: 900;
      margin-bottom: 0;
    }
  
    &__description {
      font-size: 17px;
  }
  
    .c-heading__content{
      display: none;
    }
  }
}