.c-modal__overlay {
  height: 100vh;
  width: 100%;
  background-color: hsla(0, 0%, 32%, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease all;
  cursor: pointer;
  z-index: 1001;

  &.mobile {
    background-color: var(--background-layer-secondary);
    align-items: flex-start;
    top: 68px;
  }
}

.c-modal {
  width: 460px;
  padding: 16px;
  background: var(--background-layer-secondary);
  border-radius: 4px;
  border: 1px solid var(--border-color-main);
  animation: modal-in 0.5s;
  outline: none;

  &.mobile {
    width: 100%;
    border: none;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;

    &__title {
      font-size: 24px;
      line-height: 1.5;
    }
  }

  &__content {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 48px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    &.filters {
      padding-top: 120px;
    }

    .c-button_md {
      min-width: 120px;
    }
  }

  & .c-icon-cross-round {
    fill: var(--background-icon-color-blue);
  }

  & .c-icon-warning {
    fill: var(--warning-color);
    margin-right: 15px;
  }
}


// @media screen and (max-width:767px) {
//   .c-modal {
//     height: inherit;
//     width: 100vw;
//     &__overlay {
//       display: block;
//       height: 100%;
//     }
//   }
// }