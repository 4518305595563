.c-board {
  display: grid;
  grid-template-columns: 100% 265px;
  grid-gap: 24px;
  width: 100%;
  max-width: 100%;

  &.actions-mode_close {
    animation: actions-mode-close 0.2s linear;
  }

  &-search {
    display: none;
  }

  &__task {
    margin-bottom: 12px;

    &.empty-card {
      -ms-touch-action: none;
      pointer-events: none;
      touch-action: none;
      user-select: none;
      border-radius: 4px;
      border: 4px solid rgba(0, 0, 0, 0.30);
      background: var(--background-layer-additional);
    }
    
    &.draggable {
      position: fixed;
      top: 0px;
      z-index: 5000;

      .c-task-card {
        pointer-events: none;
        touch-action: none;
        -ms-touch-action: none;
        box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.5);
        border: 2px solid var(--color-lime);
        transition-duration: 0.3s;
        transform: rotate(6deg);
      }
    }
  }

  &.dragging-status {
    user-select: none;

    .c-board__header {
      pointer-events: none;
    }

    .c-status-card-add-button {
      display: none;
    }
  }

  .c-user-list {
    padding: 8px 0px;
  }

  &.actions-mode {
    grid-template-columns: minmax(auto, calc(100% - 280px)) 265px;
    grid-gap: 16px;

    &.actions-mode_open {
      animation: actions-mode-open  0.2s linear;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .c-heading {
      max-width: calc(100% - 16px);
      width: auto;
      margin-right: 44px;
      white-space: nowrap;
      margin-bottom: 0px;
    }

    .c-user-list {
      margin-right: 36px;
    }

    .c-icon-star {
      fill: var(--color-brand);

      margin-right: 16px;
    }

    .c-icon_md.c-icon-folder {
      &.c-icon-star {
        min-width: 24px;
      }
      
      &.c-icon-folder {
        min-width: 24px;
      }
    }
  
    .c-icon-folder {
      fill: var(--background-icon-color-gray);
      margin-right: 16px;
    }

    .c-additional-actions {
      display: flex;
      align-items: center;
    }
  }

  .panel-tooltip {
    z-index: 2;
  }

  &__panel {
    display: grid;
    grid-template-columns: auto auto 1fr;
    margin-left: 52px;
    height: fit-content;
  }

  &__task {
    font-family: inherit;
  }

  .c-color-mark-bar_top {
    border-radius: 4px 4px 0px 0px;
    top: -1px;
    width: calc(100% + 2px);
    left: -1px;
  }

  .c-panel {
    width: 100%;
    padding: 16px;
    position: relative;
    min-height: 430px;

    &:nth-child(2) {
      .c-panel__content {
        height: 100%;
      }
    }

    &:has(.c-color-mark-bar_top) {
      padding-top: 19px;
    }
  }
}


@media screen and (max-width: 767px) {
  html[data-theme='dark'] {
    .c-board {
      &-search {
        &__input {
          color: var(--text-color-secondary);
        }
      }

      .c-icon-plus {
        stroke: var(--color-white);
      }
    }
  }

  .c-board {
    grid-template-columns: 100% 224px;

    &__type-board {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    &-search {
      display: block;
      position: relative;
      margin-bottom: 24px;

      &__input {
        background-color: var(--color-white);
        border-radius: 16px;
        border: none;
        width: 100%;
        outline: none;
        font-size: 16px;
        line-height: 1.5em;
        padding: 12px 20px 12px 44px;

        &::placeholder {
          color: var(--color-gray-light);
          line-height: 1.5em;
        }

        &:focus {
          box-shadow: initial;
        }
      }

      .c-icon-search {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        left: 20px;
      }
    }

    &__header {
      .c-additional-actions {
        display: none;
      }
    }

    &__users-panel {
      display: flex;
      align-items: center;
    }

    &__header {
      flex-wrap: initial;
      display: grid;
      grid-template-columns: auto minmax(72%, 90%) 26px;
      grid-gap: 8px;
      flex-wrap: nowrap;

      .c-heading {
        max-width: calc(100% - 18px);
        width: fit-content;
        margin-right: initial;
        position: relative;

        &__content {
          min-height: 28px;
          font-size: 24px;
          font-weight: 500;
        }

        &__action {
          position: absolute;
          top: 0px;
          right: -14px;
        }

        &__icon-edit {
          .c-icon-edit {
            height: 20px;
            width: 18px;
            top: -2px;
            left: 2px;
          }
        }
      }

      .c-icon-star-disable {
        fill: inherit;
        margin-right: 0px;
      }

      .c-icon-star {
        margin-right: 0px;
      }

      .c-icon-folder {
        margin-right: 0px;
        position: relative;
        top: 2px;
      }

      .c-icon-additional_actions {
        width: 26px;
      }
    }

    &-button-bar {
      display: none;
    }

    .c-color-mark-bar_top {
      display: none;
    }

    .c-icon-plus {
      stroke: var(--color-graphite-light);
      stroke-width: 1.4px;
    }

    .c-user-list {
      .c-user-avatar_sm,.c-icon_sm {
        width: 36px;
        height: 36px;
      }
    }

    .c-task-card-footer {
      .c-user-avatar {
        width: 20px;
        height: 20px;
      }
    }

    .c-panel {
      &:nth-child(1) {
        background-color: inherit;
        border: 0px;
        box-shadow: none;
        padding: 0px;
        min-height: auto;
      }

      &:nth-child(2) {
        display: none;
        border: 0px;
        margin: 0px;
        overflow: hidden;
        padding: 0px;
        height: 100%;
      }
    }

    &.actions-mode {
      grid-template-columns: 100% 100%;
      grid-gap: 0px;

      &.actions-mode_open {
        animation: none;
      }

      .c-panel {
        &:nth-child(2) {
          display: initial;
          left: 0;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 1000;
        }
      }
    }

    &.actions-mode_close {
      animation: none;
    }

    &__content {
      .c-color-mark-bar.c-color-mark-bar_top {
        display: none;
      }
    }
  }
}
