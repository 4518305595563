.c-task-actions {
  position: absolute;
  bottom: 16px;
  width: calc(100% - 32px);

  .c-task__title {
    margin-bottom: 8px;
  }

  .c-button_sm {
    width: 100%;
  }

  .c-icon-bin {
    fill: var(--color-blue);
  }
}

.c-modal.mobile.modal-task-action {
  .c-modal__footer {
    flex-direction: column;

    & .c-button:first-child {
      margin-bottom: 16px;
    }
  }
}
