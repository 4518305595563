.c-timer {
  display: flex;
  justify-content: center;

  &.show-unit-date-time {
    min-height: 48px;

    &__delimiter {
      padding: 0px 16px;
    }
  }

  &.hidden {
    display: none;
  }

  &__delimiter {
    display: flex;
    align-items: center;
    padding: 0px 8px;
  }

  &__message {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--confirm-color);

    &.error {
      color: var(--danger-color);
    }
  }

  .c-unit-date-time {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.success-finish {
      color: var(--confirm-color);
    }
  
    &.danger {
      color: var(--danger-color);
    }
  }
}
