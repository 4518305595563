.c-promo {

  &__container {
    max-width: 1200px;
    padding: 48px;
  }

  &__logo {
    color: var(--color-brand);
    padding: 0px 8px;
    font-weight: initial;
  }

  &__white{
      color: var(--color-white);
  }
}

@media (max-width:768px){
  .c-promo__container{
    padding:25px;
    max-width: 100%;
  }
}
