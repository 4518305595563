.c-tag {
  display: block;
  line-height: 1.5;
  padding: 4px 8px;
  background-color: var(--color-mint-light);
  color: var(--color-graphite);
  border-radius: 4px;
  cursor: pointer;
  max-width: 146px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

html[data-theme='dark'] {
  .c-tag {
    background-color: var(--color-gray-light);
    color: var(--color-white);
  }
}