@import '../../assets/styles/mixins';

.c-autocomplete-tags{
    font-family: sans-serif;
    position: relative;

    &__input{
      border: none;
      border-bottom: 1px solid var(--color-silver);
      width: 200px;
      margin-top: 16px;
    
      &:focus {
        outline: none;
      }
    }

    &__no-tags{
      position: absolute;
      z-index: 1;
      border: 1px solid var(--color-silver-light);
      background-color: var(--color-white);
      color: var(--color-gray);
      padding: 0.5rem;
      width: 200px;
      border-radius: 8px;
    }

    &__tag-list {
      position: absolute;
      z-index: 1;
      border: 1px solid var(--color-silver-light);
      list-style: none;
      margin-top: 0;
      max-height: 143px;
      overflow-y: auto;
      padding-left: 0;
      min-width: 200px;
      width: max-content;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      background-color: var(--color-white);
      @include custom-scroll-bar();
    }
    
    &__tag-list li {
      padding: 0.5rem;
      transition: background-color 0.3s;
    }

    &__tags-active,
    &__tag-list li:hover{
        background-color: var(--color-silver);
        color: var(--color-graphite);
        cursor: pointer;
        font-weight: 700;
    }
    
    &__tag-list li:not(:last-of-type) {
        border-bottom: 1px solid var(--color-gray);
    }
}

html[data-theme='dark'] {
  .c-autocomplete-tags {
    &__input {
      background-color: var(--background-layer-secondary);
      border-color: var(--color-white);
      color: var(--color-white);
    }

    &__tag-list, &__no-tags {
      background-color: var(--color-graphite-light);
      color: var(--color-white);
    }
  }
}
