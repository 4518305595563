.c-task-card-header {
  line-height: 18px;
  size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 5px;
  overflow-wrap: break-word;
  cursor: pointer;

  &:hover {
    color: var(--text-color-quaternary);
    transition: all 0.5s ease;
  }

  &__board-index {
    font-weight: 700;
  }
}
