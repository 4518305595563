.v-board {
  animation: fade-in 0.2s;
  overflow: hidden;
}


@media screen and (max-width:767px) {
  .v-board {
    position: relative;
    padding-top: 28px;

    .c-loader {
      background-color: inherit;
      border: none;
    }
  }
}
