.c-select-control {
  display: block;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 4px;
    height: 64px;
    background-color: var(--mark-color-blue);
    z-index: 1;
  }

  &.disabled {
    pointer-events: none;

    &::before {
      background-color: var(--mark-color-graphite-light);
    }

    .c-select-control__select-text {
      color: var(--color-graphite-light);
    }
  }

  &_required {
    .c-select-control__label-text {
      &::after {
        position: absolute;
        top: -6px;
        color: var(--danger-color);
        font-weight: 900;
        content: "*";
      }
    }
  }

  &__list {
    width: 100%;
    position: absolute;
    z-index: 2;
    visibility: hidden;
    overflow-y: auto;

    .c-select-control__wrap-option {
      position: relative;
      bottom: 40px;
      width: 100%;
      padding: 8px 8px 8px 12px;
      border-radius: 0px 0px 0px 0px;
      background-color: var(--background-layer-quaternary);
      border-bottom: 1px solid var(--border-color-main);
      border-right: 1px solid var(--border-color-main);
      margin-bottom: -40px;
      max-height: 40px;
      transition: all 0.2s;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        display: inline-block;
        width: 4px;
        height: 40px;
        background-color: var(--mark-color-blue);
      }

      &:hover {
        background-color: var(--background-layer-additional);
      }

      &:last-child {
        border-radius: 0px 0px 4px 0px;
      }
    }

    .c-option {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: var(--text-color-tetriary);
    }
  }

  &.active {
    .c-select-control__select-text {
      border-radius: 0px 4px 0px 0px;
    }

    .c-select-control__list {
      visibility: visible;

      .c-select-control__wrap-option {
        bottom: 0px;
        margin-bottom: 0px;
        transition: all 0.2s;
      }
    }
  }

  &__label-text {
    padding-left: 12px;
    height: 1.5em;
  }

  &__icon-wrap {
    position: absolute;
    top: 24px;
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: flex-end;
    cursor: pointer;
  }

  &__select {
    border: none;
    outline: none;
    width: 100%;
    height: 40px;
    padding: 8px 40px 8px 12px;
    visibility: hidden;
  }

  &__select-text {
    display: inline-block;
    font-size: 16px;
    line-height: 40px;
    width: 100%;
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 40px 0px 12px;
    cursor: pointer;
    background-color: var(--background-layer-tetriary);
    border: 1px solid var(--border-color-main);
    border-radius: 0px 4px 4px 0px;
    position: absolute;
    right: 0%;
    color: var(--text-color-tetriary);
  }

  .c-icon-chevron-down {
    position: relative;
    top: 32%;
    right: 4%;
    fill: var(--background-icon-color-blue);
  }

  .c-icon-block-round {
    position: relative;
    top: 24%;
    right: 4%;
    fill: var(--background-icon-color-gray);
  }

  &__bottom-text {
    color: var(--danger-color);
    height: 24px;
    padding-left: 12px;
    font-size: 12px;
    display: inline-block;
  }
}

@media screen and (max-width: 767px) {
  .c-select-control {
    &__select-text {
      background-color: var(--background-layer-quaternary);
    }
  }
}
