.c-panel {
  background-color: var(--background-layer-secondary);
  border: 1px solid var(--border-color-main);
  border-radius: 4px;
  box-shadow: var(--box-shadow-primary);
  padding: 16px 24px;
  margin-bottom: 24px;

  .c-heading {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 767px) {
  .c-panel {
    padding: 16px 24px;
    margin-bottom: 24px;
    border-radius: 4px;

    .c-heading {
      margin-bottom: 16px;
    }
  }
}

