.c-board-actions-panel {
    &__color-mark {
        position: relative;
        z-index: 2;
    
        .c-icon-color-pattern {
          fill: var(--background-icon-color-blue);
        }
      }
    
      &__color-mark-header {
        font-size: inherit;
      }
    
      &__color-mark-title {
        font-size: inherit;
      }
    
      &__color-board {
        height: 30px;
        border-radius: 4px;
        cursor: pointer;
    }
    .__blanked {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
}

@media screen and (max-width:767px) {
  .c-board-actions-panel {
    &__color-mark {
      margin-bottom: 10px;
    
      .c-icon-color-pattern {
        fill: var(--color-white);
      }
    }
  }
}
