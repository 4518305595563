.c-task-card {
  border: 1px solid var(--border-color-main);
  background: var(--background-layer-quaternary);
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 8px;
  cursor: grab;
  user-select: none;

  .c-icon-chevron-right {
    display: none;
  }

  &.c-color-mark-bar_left {
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
  }
}


@media screen and (max-width:767px) {
  .c-task-card {
    background-color: var(--color-silver-light);
    box-shadow: initial;
    border: initial;
    height: 34px;
    padding: 8px 0px 8px 8px;

    &-header {
      color: var(--color-gray);
      width: 100%;
    
      &__board-index {
        color: var(--color-graphite);
      }
    }
    .c-icon-chevron-right {
      display: block;
      margin-left: 34px;
    }

  &-footer {
    &__user {
      width: 20px;
      height: 20px;
    }
  }
  }
}
