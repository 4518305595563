.c-task-header {
  margin-bottom: 16px;
  
  &__board-index {
    font-size: 24px;
    font-weight: 700;
  }

  & .c-heading {
    &__content {
      width: calc(100% - 16px - 4px);
      overflow-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &__input {
        width: 100%;
      }
    }
  }

  & .c-icon-expand, .c-icon-copy {
    fill: var(--background-icon-color-blue);
    margin-left: 10px;
    cursor: pointer;
  }
}
