#L7 {
  width: 160px;
  height: 160px;
}

.c-loader {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-layer-secondary);
  border: 1px solid var(--border-color-main);
  border-radius: 4px;
  width: 100%;
  opacity: 0.9;
  height: 100%;
  animation: fade-in 0.3s ease-in-out;
  top: 0;
  left: 0;

  &__container {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  &__container div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--color-aqua);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  &__container div:nth-child(1) {
    left: 8px;
    animation: loader_1 0.6s infinite;
  }

  &__container div:nth-child(2) {
    left: 8px;
    animation: loader_2 0.6s infinite;
  }

  &__container div:nth-child(3) {
    left: 32px;
    animation: loader_2 0.6s infinite;
  }

  &__container div:nth-child(4) {
    left: 56px;
    animation: loader_3 0.6s infinite;
  }

  @keyframes loader_1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes loader_2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }

  @keyframes loader_3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }
}
