.c-filter-modal {

  .c-modal__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
  }

  &__input {
    .disable {
      &:checked::after {
        content: "";
        display: inline-block;
        position: relative;
        width: 6px;
        height: 3px;
        bottom: 101%;
        left: 1px;
        background-color: var(--color-gray-light);
        transform: rotate(47deg);
        border-radius: 1px;
      }
      &:checked::before {
        content: "";
        display: inline-block;
        position: relative;
        width: 82%;
        height: 3px;
        bottom: 13%;
        left: 3px;
        background-color: var(--color-gray-light);
        border-radius: 1px;
        transform: rotate(135deg);
      }
    }
  }
}

.c-filter-modal {
  width: 460px;
  height: 457px;
  flex-shrink: 0;

  &__checkboxes {
    background-color: var(--background-layer-additional);

    .c-checkbox-control {
      padding: 8px; 
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__title {
        padding: 4px;
        margin: 0px;
        cursor: pointer;
        width: 100%;
        color: var(--text-color-tetriary)
      }

      &__input {
        .disable {
          &:checked::after {
            content: "";
            display: inline-block;
            position: relative;
            width: 6px;
            height: 3px;
            bottom: 101%;
            left: 1px;
            background-color: var(--background-layer-tetriary);
            transform: rotate(47deg);
            border-radius: 1px;
          }

          &:checked::before {
            content: "";
            display: inline-block;
            position: relative;
            width: 82%;
            height: 3px;
            bottom: 13%;
            left: 3px;
            background-color: var(--color-gray-light);
            border-radius: 1px;
            transform: rotate(135deg);
          }
        }
      }

      &__description {
        display: none;
      }

      &:nth-child(even) {
        background-color: var(--color-silver-light);
      }
    }
  }
}

html[data-theme='dark'] {
  .c-filter-modal {
    &__checkboxes {
      background-color: var(--color-jet-black);

      .c-checkbox-control {
        &__title {
          color: var(--color-white);
        }

        &__input {
          border-color: var(--color-gray-light);

          &:checked::after {
            background-color: var(--color-white);
          }

          &:checked::before{
            background-color: var(--color-white);
          }
        }
        
        &:nth-child(even) {
          background-color:var(--color-deep-gray);
        }
      }
    }
  }
}

@media screen and (max-width:767px) {
  .c-filter-modal {
    width: inherit;
    height: inherit;
  }
}