.c-auth-form-social-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  margin-bottom: 24px;

  &_button {
    width: 100%;

    .c-button {
      height: 60px;
      border: 1px solid var(--color-gray-light);
      color: var(--color-black);
      display: flex;
      flex-direction: row;
      justify-content: left;

      [class^="c-icon"] {
        fill: none;
        stroke: none;
      }

      &__icon {
        span {
          width: 36px;
          height: 36px;
        }
      }

      &:hover {
        border-color: var(--color-gray-light);
        color: var(--color-black);

        [class^="c-icon"] {
          fill: none;
          stroke: none;
        }
      }
    }

    + .c-auth-form-social-buttons_button {
      margin-top: 12px;
    }
  }
}

html[data-theme="dark"] {
  .c-auth-form-social-buttons {
    .c-button {
      color: var(--color-white);
    }
  }
}