.c-task-priority {
  margin-bottom: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    & .c-task-priority__priority {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--info-color);

      span {
        margin-bottom: 8px;
      }

      .c-priority-icon{
        margin-left: 8px;
        height: 24px;
      }
    }
  
    & .c-task__title {
      margin-bottom: 8px;
    }
  }
}
