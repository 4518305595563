@import '../../assets/styles/mixins';

.c-board-actions-panel__info {

  &__info-header {
    font-size: inherit;
  }

  &__info-title {
    font-size: inherit;
  }

  &-description {
    // display: -webkit-box;
    // -webkit-line-clamp: 5;
    // -webkit-box-orient: vertical;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
}

.c-modal {
  .c-textarea__textarea {
    @include custom-scroll-bar();
  }
}

@media screen and (max-width:767px) {
  .c-board-actions-panel {
    &__info {
      margin-bottom: 10px;
    }
  }
}
