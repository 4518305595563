@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modal-in {
  0% {
    opacity: 0;
    translate: 0 -40px;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}

// @keyframes actions-mode-close {
//   0% {
//     grid-template-columns: minmax(auto, calc(100% - 280px)) 265px;
//     grid-gap: 16px;
//   }
//   50% {
//     grid-template-columns: minmax(auto, calc(100% - 140px)) 265px;
//     grid-gap: 16px;
//   }
//   100% {
//     grid-template-columns: minmax(auto, calc(100% - 0px)) 265px;
//     grid-gap: 16px;
//   }
// }

@keyframes actions-mode-close {
  0% {
    grid-template-columns: calc(100% - 280px) 265px;
    grid-gap: 16px;
  }
  50% {
    grid-template-columns: calc(100% - 140px) 265px;
    grid-gap: 16px;
  }
  100% {
    grid-template-columns: calc(100% - 0px) 265px;
    grid-gap: 16px;
  }
}

@keyframes actions-mode-open {
  0% {
    grid-template-columns: calc(100% - 0px) 265px;
    grid-gap: 24px;
  } 
  50% {
    grid-template-columns: calc(100% - 140px) 265px;
    grid-gap: 16px;
  }
  100% { 
    grid-template-columns: calc(100% - 280px) 265px;
    grid-gap: 16px;
  }
}

@keyframes actions-mode-view-mobile {
  0% {
    right: -100%;
  } 
  50% {
    right: -50%;
  }
  100% { 
    right: 0%;
  }
}
