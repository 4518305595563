.c-error-message {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__name {
    font-size: 36px;
    font-weight: 700;
    color: var(--text-color-primary);
    text-align: center;
  }

  &__description {
    text-align: center;
    font-size: 24px;
    color: var(--text-color-tetriary);
  }

  &__text {
    padding-top: 24px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    max-width: 530px;
  }

  &__number {
    font-size: 72px;
    color: var(--danger-color);
    font-weight: 700;
    line-height: 1em;
  }

  .c-icon {
    width: 90px;
  }
}

@media screen and (max-width: 767px) {
  .c-error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    &__name {
      font-size: 24px;
    }

    &__description {
      font-size: 16px;
    }

    &__text {
      padding-top: 8px;
      padding-bottom: 8px;
      max-width: 338px;
    }

    &__number {
      font-size: 54px;
    }
  
    .c-icon {
      width: 60px;
    }

    .c-button {
      font-size: 14px;
      height: 30px;
      min-width: 80px;
      padding: 0px 10px;
    }
  }
}