.c-heading {
  width: 100%;
  display: inline-flex;

  &.primary {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.5;
  }

  &.secondary {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
  }

  &__content {
    min-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;

    &__input {
      background-color: inherit;
      color: var(--text-color-primary);
      outline: none;
      appearance: none;
      border: none;
      border-bottom: 2px solid var(--color-blue);
      padding: 0;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: calc(100% - 8px);
      line-height: 36px;

      .primary & {
        font-size: 36px;
        font-weight: 700;
      }

      .secondary & {
        font-size: 24px;
        font-weight: 400;
      }
    }
  }

  &__icon-edit {
    position: relative;
    width: 16px;

    .c-icon-edit {
      position: absolute;
      top: -4px;
      left: 4px;
      stroke: var(--background-icon-color-blue);
    }
  }

  &__action {
    display: flex;
    flex-direction: column;

    & .c-icon-save {
      fill: var(--color-aqua);
      line-height: 16px;
      margin-bottom: 4px;
    }

    & .c-icon-cross-round {
      fill: var(--color-coral);
      line-height: 16px;
    }
  }

  &__iconLeft {
    margin-right: 5px;

    .primary & {
      max-height: 54px;
    }

    .secondary & {
      max-height: 36px;
    }
  }

  &__iconRight {
    margin-left: 5px;

    .primary & {
      height: 54px;
    }

    .secondary & {
      height: 36px;
    }
  }
}

@media screen and (max-width: 768px){
  .c-heading {
    &__content {
      &__input {
        -webkit-line-clamp: initial;
      }
    }
  
    &.primary {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.5;
    }
  
    &.secondary {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}
