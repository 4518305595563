.c-panel:has(.c-faq) {
  display: flex;
  justify-content: center;
  flex-grow: 1;

  .c-panel__content {
    width: 100%;
    max-width: 800px;
  }
}

.c-faq {
  padding-top: 32px;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;

    .c-heading {
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 0px;

      &__content {
        min-height: auto;
      }
    }

    .c-icon {
      fill: var(--info-color);
      margin-right: 16px;
    }
  }

  &__list {
    width: 100%;

    &__item {
      width: 100%;
      border: 1px solid var(--border-color-main);
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0px;
      }

      &__question {
      display: flex;
      flex-direction: row;
      background-color: var(--background-layer-additional);

      &__icon {
        display: flex;
        align-items: center;
        padding: 16px 0px 16px 16px;

        .c-icon {
          fill: var(--info-color);
        }
      }

        &__text {
          display: flex;
          align-items: center;
          padding: 16px;
          width: calc(100% - 80px);
          line-height: 1.5;
          font-size: 20px;
        }
        
        &__button {
          background-color: var(--color-blue);
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .c-icon {
            stroke: var(--color-white);
          }

          &.open {
            background-color: var(--color-gray-light);

            .c-icon {
              stroke: var(--color-graphite);
            }
          }
        }
      }

      &__answer {
        visibility: hidden;
        height: 0px;


        &.visible {
          visibility: visible;
          padding: 16px;
          font-size: 18px;
          height: auto;
        }
      }
    }
  }
}