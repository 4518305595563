.c-todo-item {
  font-size: 16px;
  
  &.selected {
    text-decoration: line-through;
    color: var(--text-color-tetriary);
  }

  &.done {
    text-decoration: line-through;
    color: var(--color-gray);  
  }

}

.c-todo-list__todo-item__checkbox {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    vertical-align: middle;
}