.c-button {
  cursor: pointer;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border-radius: 4px;
  padding: 0px 16px;
  text-align: center;
  transition: all 0.2s ease-out;
  min-width: 120px;

  &_xl {
    font-size: 20px;
    font-weight: 700;
    height: 46px;
    min-width: 145px;
  }

  &_lg {
    font-size: 18px;
    height: 40px;
    min-width: 124px;
  }

  &_md {
    font-size: 16px;
    height: 36px;
    min-width: 80px;
    padding: 0px 14px;
  }

  &_sm {
    font-size: 14px;
    height: 30px;
    min-width: 80px;
    padding: 0px 10px;
  }

  &_xs {
    font-size: 12px;
    height: 24px;
    padding: 0px 8px;
    min-width: 56px;
  }

  &.primary {
    background-color: var(--color-blue);
    color: var(--color-white);
    border: 1px solid var(--color-blue);

    [class^="c-icon"] {
      fill: var(--color-white);
      stroke: var(--color-white);
    }

    &:focus {
      outline: 1px solid var(--color-white);
      outline-offset: -4px;
    }

    &:hover {
      background-color: var(--color-azure);
      border: 1px solid var(--color-azure);      
    }

    &:active {
      background-color: var(--color-cobalt);
      border: 1px solid var(--color-cobalt); 
      outline: none;
    }

    &:disabled {
      background-color: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
      cursor: not-allowed;
    }
  }

  &.primary-inverse  {
    background-color: inherit;
    color: var(--color-blue);
    border: 1px solid var(--color-blue);

    [class^="c-icon"] {
      fill: var(--color-blue);
      stroke: var(--color-blue);
    }

    &:focus {
      outline: 1px solid var(--color-blue);
      outline-offset: -4px;
    }

    &:hover {
      background-color: inherit;
      border: 1px solid var(--color-azure);
      color: var(--color-azure);
      
      [class^="c-icon"] {
        fill: var(--color-azure);
        stroke: var(--color-azure);
      }
    }

    &:active {
      background-color: inherit;
      border: 1px solid var(--color-cobalt);
      color: var(--color-cobalt);
      outline: none;

      [class^="c-icon"] {
        fill: var(--color-cobalt);
        stroke: var(--color-cobalt);
      }
    }

    &:disabled {
      background-color: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
      color: var(--color-white);
      cursor: not-allowed;

      [class^="c-icon"] {
        fill: var(--color-white);
        stroke: var(--color-white);
      }
    }
  }

  &.secondary {
    background-color: var(--color-graphite-light);
    color: var(--color-white);
    border: 1px solid var(--color-graphite-light);

    [class^="c-icon"] {
      fill: var(--color-white);
      stroke: var(--color-white);
    }

    &:focus {
      outline: 1px solid var(--color-white);
      outline-offset: -4px;
    }

    &:hover {
      background-color: var(--color-charcoal);
      border: 1px solid var(--color-charcoal);      
    }

    &:active {
      background-color: var(--color-violet);
      border: 1px solid var(--color-violet);
      outline: none;
    }

    &:disabled {
      background-color: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
      cursor: not-allowed;
    }
  }

  &.secondary-inverse  {
    background-color: inherit;
    color: var(--color-graphite-light);
    border: 1px solid var(--color-graphite-light);

    [class^="c-icon"] {
      fill: var(--color-graphite-light);
      stroke: var(--color-graphite-light);
    }

    &:focus {
      outline: 1px solid var(--color-graphite-light);
      outline-offset: -4px;
    }

    &:hover {
      background-color: inherit;
      border: 1px solid var(--color-charcoal);
      color: var(--color-charcoal);
      
      [class^="c-icon"] {
        fill: var(--color-charcoal);
        stroke: var(--color-charcoal);
      }
    }

    &:active {
      background-color: inherit;
      border: 1px solid var(--color-violet);
      color: var(--color-violet);
      outline: none;

      [class^="c-icon"] {
        fill: var(--color-violet);
        stroke: var(--color-violet);
      }
    }

    &:disabled {
      background-color: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
      color: var(--color-white);
      cursor: not-allowed;

      [class^="c-icon"] {
        fill: var(--color-white);
        stroke: var(--color-white);
      }
    }
  }

  &.confirm {
    background-color: var(--color-aqua);
    border: 1px solid var(--color-aqua);
    color: var(--color-white);

    [class^="c-icon"] {
      fill: var(--color-white);
      stroke: var(--color-white);
    }

    &:focus {
      outline: 1px solid var(--color-white);
      outline-offset: -4px;
    }

    &:hover {
      background-color: var(--color-turquoise);
      border: 1px solid var(--color-turquoise);      
    }

    &:active {
      background-color: var(--color-pine-green);
      border: 1px solid var(--color-pine-green);
      outline: none; 
    }

    &:disabled {
      background-color: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
      cursor: not-allowed;
    }
  }

  &.confirm-inverse  {
    background-color: inherit;
    color: var(--color-aqua);
    border: 1px solid var(--color-aqua);

    [class^="c-icon"] {
      fill: var(--color-aqua);
      stroke: var(--color-aqua);
    }

    &:focus {
      outline: 1px solid var(--color-aqua);
      outline-offset: -4px;
    }

    &:hover {
      background-color: inherit;
      border: 1px solid var(--color-turquoise);
      color: var(--color-turquoise);
      
      [class^="c-icon"] {
        fill: var(--color-turquoise);
        stroke: var(--color-turquoise);
      }
    }

    &:active {
      background-color: inherit;
      border: 1px solid var(--color-pine-green);
      color: var(--color-pine-green);
      outline: none;

      [class^="c-icon"] {
        fill: var(--color-pine-green);
        stroke: var(--color-pine-green);
      }
    }

    &:disabled {
      background-color: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
      color: var(--color-white);
      cursor: not-allowed;

      [class^="c-icon"] {
        fill: var(--color-white);
        stroke: var(--color-white);
      }
    }
  }

  &.danger {
    background-color: var(--color-red);
    border: 1px solid var(--color-red);
    color: var(--color-white);

    [class^="c-icon"] {
      fill: var(--color-white);
      stroke: var(--color-white);
    }

    &:focus {
      outline: 1px solid var(--color-white);
      outline-offset: -4px;
    }

    &:hover {
      background-color: var(--color-rosso);
      border: 1px solid var(--color-rosso);      
    }

    &:active {
      background-color: var(--color-venetian-red);
      border: 1px solid var(--color-venetian-red); 
      outline: none;
    }

    &:disabled {
      background-color: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
      cursor: not-allowed;
    }
  }

  &.danger-inverse  {
    background-color: inherit;
    color: var(--color-red);
    border: 1px solid var(--color-red);

    [class^="c-icon"] {
      fill: var(--color-red);
      stroke: var(--color-red);
    }

    &:focus {
      outline: 1px solid var(--color-red);
      outline-offset: -4px;
    }

    &:hover {
      background-color: inherit;
      border: 1px solid var(--color-rosso);
      color: var(--color-rosso);
      
      [class^="c-icon"] {
        fill: var(--color-rosso);
        stroke: var(--color-rosso);
      }
    }

    &:active {
      background-color: inherit;
      border: 1px solid var(--color-venetian-red);
      color: var(--color-venetian-red);
      outline: none;

      [class^="c-icon"] {
        fill: var(--color-venetian-red);
        stroke: var(--color-venetian-red);
      }
    }

    &:disabled {
      background-color: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
      color: var(--color-white);
      cursor: not-allowed;

      [class^="c-icon"] {
        fill: var(--color-white);
        stroke: var(--color-white);
      }
    }
  }

  &.brand {
    background-color: var(--color-brand);
    border: 1px solid var(--color-brand);
    color: var(--color-graphite);

    [class^="c-icon"] {
      fill: var(--color-graphite);
      stroke: var(--color-graphite);;
    }

    &:focus {
      outline: 1px solid var(--color-graphite);
      outline-offset: -4px;
    }

    &:hover {
      background-color: var(--color-graphite-light);
      color: var(--color-brand);

      [class^="c-icon"] {
        fill: var(--color-brand);
        stroke: var(--color-brand);
      }
    }

    &:active {
      background-color: var(--color-graphite);
      border: 1px solid var(--color-brand); 
      outline: none;

      [class^="c-icon"] {
        fill: var(--color-brand);
        stroke: var(--color-brand);
      }
    }

    &:disabled {
      background-color: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
      color: var(--color-white);
      cursor: not-allowed;

      [class^="c-icon"] {
        fill: var(--color-white);
        stroke: var(--color-white);
      }
    }
  }

  &__icon {
    .c-icon_md {

      .c-button_xl & {
        width: 20px;
      }

      .c-button_lg & {
        width: 18px;
      }

      .c-button_md & {
        width: 16px;
      }

      .c-button_sm & {
        width: 14px;
      }

      .c-button_xs & {
        width: 12px;
      }
    }
  }
}

span+.c-button__icon {
  margin-left: 10px;
}

.c-button__icon+span {
  margin-left: 10px;
}
