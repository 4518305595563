@import '../../assets/styles/keyframes';

.c-status-card {
  width: 216px;
  min-width: 216px;
  min-height: 300px;
  border-radius: 4px;
  background-color: var(--background-layer-tetriary);
  border: 1px solid var(--border-color-main);
  padding: 8px;
  margin-right: 12px;
  position: relative;
  display: flex;
  flex-direction: column;

  .c-color-mark-bar {
    cursor: grab;
  }

  .c-status-card__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    &__title {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 500;
      max-width: 178px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 4px;
    }

    .c-icon-dots {
      fill: var(--background-icon-color-blue);
      cursor: pointer;
    }
    .c-icon-chevron-down {
      display: none;
    }
  }

  &.c-status-card_dragging {
    pointer-events: none;
    touch-action: none;
    -ms-touch-action: none;
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.5);
    border: 2px solid var(--color-lime);
  }

  &__edit-mode__wrapper {
    width: calc(100% + 2px);
    height: calc(100% - 2px);
    position: absolute;
    z-index: 502;
    top: 3px;
    left: -1px;
    border-radius: 0px 0px 4px 4px;
    background-color: #a4a4a499;
    border: 1px solid #a4a4a499;
    border-top: none;
    padding: 0px 8px;
    display: flex;
    justify-content: center;
    opacity: 0;
    animation: fade-in 0.5s forwards;
  }

  &__edit-mode {
    width: 100%;
    height: fit-content;
    background-color: var(--background-layer-quaternary);
    border: 1px solid var(--border-color-main);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 5px 8px 12px;
    
    &__input {
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      color: var(--text-color-primary);
      background-color: inherit;
      border: 1px solid var(--border-color-secondary);
      border-radius: 4px;
      padding: 2px 4px;
      margin-bottom: 12px;

      &:focus {
        outline: none;
      }
    }

    &__options {
      position: relative;
      z-index: 508;
      display: grid;
      grid-template-columns: auto 16px;
      gap: 24px;

      &__primary {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        .c-icon-color-pattern {
          fill: var(--color-pine-green);
        }

        .c-icon-bin {
          fill: var(--danger-color);

          &.inverse {
            fill: var(--background-icon-color-gray);
            cursor: not-allowed;
          }
        }

        .c-color-palette {
          position: absolute;
          z-index: 504;
          top: 40px;
          right: -8px;
        }
      }

      &__secondary {
        display: flex;
        align-items: center;

        .c-icon-cross-round {
          fill: var(--background-icon-color-blue);
          cursor: pointer;
        }
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
  }

  #status-card-tooltip {
    z-index: 502;
    max-width: 200px;
    text-align: center;
  }
}


@media screen and (max-width:767px) {
  .c-status-card {
    border: none;
    background-color: var(--color-white);
    border-radius: 12px;
    min-height: auto;
    padding: 12px;

    &:has(.c-board__task) {
      .c-status-card__header {
        margin-bottom: 8px;
      }
    }

    &:has(.c-status-card__edit-mode__wrapper) {
      min-height: 200px;
    }

    &:not(.c-status-card__edit-mode__wrapper) {
      >.c-button {
        background-color: inherit;
        color: var(--color-graphite-light);
        border: none;
        justify-content: start;
        font-size: 16px;
        line-height: 1.2em;
      
        .c-icon-plus {
          fill: var(--color-graphite);
          stroke: var(--color-graphite);
          stroke-width: 1.2px;
        }
      }
    } 

    &__empty-mobile {
      color: var(--text-color-additional);
    }
  
    #status-card-tooltip {
      background: inherit;
    }

    &__edit-mode__wrapper {
      background-color: inherit;
      top: initial;
    }

    .c-status-card__header {
      align-items: center;
      margin-bottom: 0px;

      &__title {
        font-weight: 500;
        font-size: 24px;
        color: var(--color-black);
        max-width: 300px;

      }
      
      .c-icon_md.c-icon-chevron-down {
        min-width: 24px;
      }

      .c-icon-dots {
        display: none;
      }

      .c-icon-chevron-down {
        display: block;
        position: relative;
        top: 2px;
      }
    }
  }
}
