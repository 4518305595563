.c-board-button-bar {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .c-button {
    margin: 4px 8px 4px 8px;

    .c-icon {
      fill: var(--color-white);
      padding-top: 4px;
    }

    .c-icon-plus {
      stroke: var(--color-white);
      width: 12px;
      padding-top: 2px;
    }

    .c-icon-user {
      width: 14px;
    }

    .c-icon-star {
      padding-top: 2px;
      margin-right: 0px;
    }
  }

  & .c-tooltip {
    &__content_bottom, &__content_top {
      left: 8px;
    }

    &__content_right {
      left: 100%;
    }

    &__content_left {
      right: 100%;
    }

    &-massage {
      &__text {
        margin-bottom: 8px;
      }

      & .c-button {
        margin-left: 0;
      }
    }
  }
}
