@import '../../assets/styles/mixins';

.c-textarea {
  display: block;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 4px;
    height: 106px;
    background-color: var(--mark-color-blue);
  }

  &.hidden {
    display: none;
  }

  &.label {
    &::before {
      height: 130px;
    }
  }

  &_required {
    .c-textarea__label-text {
      &::after {
        position: absolute;
        top: -6px;
        color: var(--danger-color);
        font-weight: 900;
        content: "*";
      }
    }
  }

  &.disabled {
    &::before {
      background-color: var(--mark-color-graphite-light);
    }
  }

  &.valid {
    &::before {
      height: 100%;
      background-color: var(--mark-color-aqua);
    }
  }

  &.error {
    &::before {
      height: 100%;
      background-color: var(--mark-color-coral);
    }

    .c-input__bottom-text {
      color: var(--danger-color);
    }
  }

  &__label-text {
    padding-left: 12px;
    height: 1.5em;
  }

  &__textarea {
    font-size: 16px;
    border-radius: 0px 4px 4px 0px;
    padding: 8px 8px 8px 12px;
    width: 100%;
    max-width: 100%;
    border: none;
    appearance: none;
    background-color: var(--background-layer-tetriary);
    color: var(--text-color-tetriary);
    resize: none;
    height: 106px;
    @include custom-scroll-bar();

    &:focus {
      box-shadow: 0px -2px 0px 0px var(--mark-color-blue) inset;
      border-radius: 0px 4px 0px 0px;
      outline: 0px;
      user-select: none;
      pointer-events: none;
      color: var(--text-color-primary);
    }
  }

  .c-icon-cross-round,
  .c-icon-block-round {
    fill: var(--background-icon-color-gray);
    position: absolute;
    right: 4px;
    top: 28px;
  }
}