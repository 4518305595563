.c-contact-form {
  padding: 24px;
  background-color: var(--background-layer-secondary);
  border: 1px solid var(--border-color-main);

  & .c-heading {
    margin-bottom: 24px;
  }

  & .c-paragraph {
    margin-bottom: 48px;
    font-size: 20px;
  }

  &__notification {
    margin-top: 24px;
    font-size: 12px;
  }
  & .c-textarea {
    &__textarea {
      background-color: var(--background-layer-tetriary);
    }
  }
}
