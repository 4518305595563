.v-user-profile {
  padding: 0px 24px 24px;
  width: 100%;

  &__wrapper {
  .c-user-profile {
    display: flex;
    flex-direction: column;
  }

  .c-panel {
    &:nth-child(1) {
      .c-heading {
        font-size: 2.2em;
        font-weight: 700;
        line-height: 1.5em;
        margin-bottom: 16px;
      }
    }

    &:nth-child(2) {
      .c-heading {
        font-size: 1.5em;
        font-weight: 700;
        margin-bottom: 22px;
      }
    }
  }
  
}

.button {
  font-weight: 500;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: inherit;
  border: 0;
  padding:5px 0;
  font-size: 12px;
  color: var(--text-color-tetriary);
  height: 26px;
  cursor: pointer;

  &:hover {
    background-color: inherit;
    border: 0;
  }
}
}


@media screen and (max-width: 767px) {
  .v-user-profile {
    padding: 16px;

    .c-panel {
      background-color: initial;
      border: initial;
      padding: 0px;
      box-shadow: initial;

      &:nth-child(1) {
        .c-heading {
          font-size: 32px;
          font-weight: 700;
          white-space: nowrap;
          margin-bottom: 12px;
        }
      }

      &:nth-child(3) {
        display: none;
      }
      &:nth-child(4) {
        display: none;
      }

      &__content {
        .c-user-edit-profile {
          .c-heading {
            margin-bottom: 24px;
            font-size: 24px;
          }
          &__appearance {
            .c-heading.secondary {
              margin-bottom: 0px;
            }
          }
        }
        .c-settings {
          .c-heading {
            font-size: 24px;
            margin: 0;
          }
        }
      }
    }

  }
}

