.c-promo-cta {
  background: var(--color-black);
  display: flex;
  justify-content: center;
  line-height: 1;

  .c-promo__container {
    display: flex;
    flex-direction: row;
    gap: 48px;
  }

  &__reverse__container {
    display: flex;
    flex-direction: row;
    gap: 48px;
    padding: 48px;
    max-width: 1200px;
  }

  &__action {
    &__title {
      font-size: 54px;
      color: var(--color-white);
      margin-bottom: 48px;
    }
  }

  .c-button.brand {
    margin-bottom: 24px;
  }

  &__slogan {
    color: var(--color-white);
    max-width: 600px;
    font-size: 24px;
    line-height: 1.5;


    &_primary {
      margin-bottom: 24px;
    }
  }
}

@media (max-width:768px){
  .c-promo-cta {
    .c-promo__container{
      gap: 30px;
      flex-direction: column;
    }
    .c-button.brand{
      width: 10em;
      font-size: 1em;
    }

    .c-button.primary {
      font-size: 0.8em;
      width: 13em;
    }

    
    .c-button.primary-inverse{
      font-size: 1em;
    }

    &__buttons{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
    }
    
    &__reverse__container{
      display: flex;
      flex-direction: column-reverse;
      max-width: 100%;
      padding: 25px;
      gap: 30px;
    }
    
    &__reverse__action{
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding-top: 25px;
    }
    
    &__action{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 25px;
    }
    
    .c-promo__logo{
      padding: 0;
    }

    &__action__title{
      display: flex;
      flex-direction: column;
      font-size: 9vw;
      padding: 5px;
      margin-bottom: 0;
    }
    
    &__title__end{
      align-self: flex-end;
    }

    &__slogan_primary{
      margin-bottom: 0;
    }
    
    &__slogan{
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  
}

// try