.c-board-actions-panel {
  &__board-actions {
    .c-button {
      margin-bottom: 6px;

      &.confirm-inverse {
        .c-icon-folder {
          stroke: var(--color-graphite-light);
        }
      }
    }
  }
  
  &__board-actions-title {
    font-size: inherit;
  }

  & .c-tooltip {
    &-massage {
      &__text {
        margin-bottom: 8px;
      }

      .c-button {
        width: initial;
      }
    }
  }
}

html[data-theme='dark'] {
  .c-board-actions-panel {
    &__board-actions {
      .c-button {
        &.secondary-inverse {
          color: var(--color-silver);
          border-color: var(--color-silver);
      
          &:hover {
            color: var(--color-orange);
            border-color: var(--color-orange);
          }
      
          .c-icon-folder {
            stroke: var(--color-gray);
            fill: var(--color-gray-light);
          }
      
          &:hover .c-icon-folder {
            stroke: var(--color-orange);
            fill: var(--color-goldenrod);
          }
        }
      }
    }
  }
}

@media screen and (max-width:767px) {
    .c-board-actions-panel__board-actions {
      .c-button {
        &.secondary-inverse {
          background-color: inherit;
          color: var(--color-silver);
          border: 1px solid var(--color-silver);
  
          .c-icon-folder {
            fill: var(--color-silver);
          }
        }
      }
    }
}
