.c-time-report-filter {
  display: flex;
  font-size: 14px;
  margin-bottom: 40px;

  &__input-date {
    display: flex;
    &:last-child {
      justify-self: end;
    }
  }

  &__wrapper-input {
    display: block;
    background-color: var(--background-icon-color-blue);
    padding: 6px 8px;
    border-radius: 5px;
    position: relative;
    border-radius: 12px;
  }

  &__input-label {
    font-weight: 500;
    margin-right: 6px;
    display: flex;
    align-items: center;
  }

  &__input{
    border: none;
    outline: none;
    background: inherit;

    &::-webkit-calendar-picker-indicator{
      cursor: pointer;
      opacity: 0;
    }

    &::-webkit-datetime-edit {
      opacity: 0;
    }
  }

  &__input-value {
    color: var(--text-color-secondary);
    position: absolute;
    left: 14px;
    bottom: 5px;
  }

  .c-icon-calendar {
    fill: var(--color-white);
    position: relative;
    right: 24px;
    top: 7px;
    pointer-events: none;
  }

  .c-button {
    height: inherit;
  }
}
