.c-board-actions-panel {
    &__board-prefix-counter {
        color: var(--color-gray-light);
      }
    
}

@media screen and (max-width:767px) {
  .c-board-actions-panel {
    &__board-prefix {
      margin-bottom: 10px;
    }
  }
}
