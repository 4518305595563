@import '../../assets/styles/mixins';

.c-board-panel {
  display: flex;
  flex-direction: column;
  position: relative;

  &.card-insertion {
    &::after {
      content: "";
      background: var(--color-aqua);
      border-radius: 4px;
      display: block;
      height: calc(100% - 40px);
      opacity: 0.6;
      position: absolute;
      right: 8px;
      top: 8px;
      width: calc(100% - 16px);
    }
  }

  &__list-board {
    display: flex;
    flex-wrap: wrap;
  }

  .c-panel {
    padding: 8px 6px 4px 6px;
  }
}

@media screen and (max-width: 767px) {
  .c-board-panel {
    max-width: 100%;
    white-space: nowrap;

    &.card-insertion {
      &::after {
        content: "";
        background: var(--color-aqua);
        border-radius: 4px;
        display: block;
        height: calc(100% - 40px);
        opacity: 0.6;
        position: absolute;
        right: 8px;
        top: 8px;
        width: calc(100% - 16px);
      }
    }
    &__list-board {
      display: flex;
      width: 100%;
      overflow-x: auto;
      flex-wrap: nowrap;
      @include custom-scroll-bar();
    }
    .c-panel {
      background-color: var(--color-silver);
      padding: 8px 6px 4px 6px;
    }
  }
}

html[data-theme='dark'] {
  @media screen and (max-width: 767px) {
    .c-board-panel {
      .c-panel {
        background-color: var(--background-layer-secondary);
      }
    }
  }
}
