.c-cookie-banner {
    position: fixed;
    bottom: 86px;
    right: 24px;
    width: auto;
    max-width: 240px;
    background-color: var(--color-graphite);
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px 24px;
    border-radius: 4px;
    box-shadow: var(--box-shadow-primary);
    z-index: 9999;
  
    &__info {
      margin: 0;
      text-align: center;
      font-size: 16px;
      margin-bottom: 12px;
    }
  
    &__button {
      background-color: #f6be00;
      color: var(--color-graphite);
      border: none;
      border-radius: 4px;
      padding: 8px 16px;
      cursor: pointer;
  
      &:hover {
        background-color: #f6c635;
      }
    }
  }
  