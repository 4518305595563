.c-user-avatar {
  position: relative;
  font-weight: 700;
  font-size: 16px;
  line-height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: inset rgba(0, 0, 0, 0.3) 0px 0px 2px,
      inset rgba(0, 0, 0, 0.3) 0px 0px 0px 2px;
    top: 0;
    left: 0;
  }

  &__icon {
    display: flex;
    color: #fff;
    width: 60%;
  }

  &__photo {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_xl {
    font-size: 40px;
    width: 64px;
    height: 64px;
  }

  &_lg {
    font-size: 32px;
    width: 56px;
    height: 56px;
  }

  &_md {
    font-size: 24px;
    width: 44px;
    height: 44px;
  }

  &_sm {
    font-size: 16px;
    width: 34px;
    height: 34px;
  }

  &_xs {
    font-size: 12px;
    width: 22px;
    height: 22px;
  }

  &.clickable {
    cursor: pointer;
  }
}

