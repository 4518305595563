.c-table {
  min-width: 100%;
  display: table;

  .c-row {
    display: table-row;

    &__cell {
      max-width: 120px;
      display: table-cell;
      border: 1px solid var(--border-color-main);
      padding: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      
    }
  }  

  &__header {
    background-color: var(--background-layer-tetriary);
    color: var(--text-color-primary);
    font-weight: bold;
    line-height: 1;
  }

  &__content {
    color: var(--text-color-tetriary);
    background-color: var(--background-layer-quaternary);
    line-height: 1;
  }
}