@import '../../assets/styles/mixins';

.c-modal {
  &.modal-top {
    position: relative;
    bottom: 10%;
    overflow: hidden;
    min-height: 460px;

    .c-modal__content {
      margin-bottom: 0px;
      height: calc(100% - 68px);
    }

    &.select-user {
      height: 460px;
    }
  }
}

.c-add-user-form {
  height: 100%;

  &__title {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 32px;
  }

  &__wrapper-select {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__description {
    margin-bottom: 24px;
    text-align: center;
    font-size: 18px;
    color:var(--text-color-tetriary);
  }

  &__back-to-search {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--info-color);
    }

    .c-icon-chevron-down {
      transform: rotate(90deg);
      fill: var(--background-icon-color-blue);
      padding-top: 2px;
    }
  }

  &__link-search {
    color: var(--info-color);
    margin-left: 5px;
  }

  &__user-list {
    height: 294px;
    overflow-y: auto;
    border: 1px solid var(--border-color-main); 
    @include custom-scroll-bar();
  }

  &__user-list-item {
    padding: 5px 0px 5px 5px;
    color: var(--text-color-tetriary);

    &:hover {
      background-color: var(--background-layer-additional);
    }
  }

  &__not-found-user {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-layer-additional);
    height: 294px;

    &__text {
      font-weight: 400;
      font-size: 20px;
    }
  }

  &__wrapper-select {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  & .c-icon-cross-round {
    top: 10px
  }
}


@media screen and (max-width:767px) {
  .c-modal.modal-top {
    bottom: 0%;
  }
}