.c-task-author {
  margin-bottom: 16px;
  
  & .c-task__title {
    margin-bottom: 8px;
  }

  & .c-user-field {
    margin-bottom: -18px;
    align-items: flex-start;
  }

  &__date-create {
    margin-left: 42px;
    color: var(--info-color);
    line-height: 18px;
  }
}
