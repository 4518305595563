.c-promo-top {
  background: var(--color-black);
  display: flex;
  justify-content: center;
  line-height: 1;

  &__title {
    font-size: 88px;
    color: var(--color-white);
    margin-bottom: 32px;

    .c-promo__logo {
      padding: 0px 16px;
    }
  }

  &__description {
    font-size: 32px;
    color: var(--color-gray-light);
    margin-bottom: 32px;
  }

  &__screen {
    display: none;
  }

  &__container{
    max-width: 1200px;
    padding: 48px;
  }

  .c-promo-top__welcome{
    color: var(--color-white)
  }

  .c-promo__white{
    padding-left: 10px;
  }

  .c-promo-not-user__logo{
    color: var(--color-brand);
    font-weight: 400;
    padding: 0px 16px;
  }
}

@media (max-width: 768px) {
  .c-promo-top{
    .c-promo__container{
      display: grid;
      padding: 20px;
      grid-template-columns: repeat(2, 1fr);

      .c-button.brand{
        width: 200%;
      }
    }

    .c-promo__white{
      padding: 0;
    }
  
    &__title{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 7vw;
      margin-bottom: 15px;
    }

    &__welcome{
      display: none;
    }

    .c-promo-not-user__logo{
      color: var(--color-brand);
      font-size: 8.6vw;
      padding: 0px;
    }
  
    .c-promo-top__title .c-promo__logo{
      display: flex;
      align-items: center;
      font-size: 10vw;
      padding: 0px 10px;
    }
  
     &__screen {
      display: block;
      min-height: 127px;
      height: calc(73vh - 68px - 40px - 16px - 358px - 48px - 48px);
      background-image: var(--background-image);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-bottom: 15px;
    }
  
    &__description{
      grid-column: 1 / span 2;
      text-align: center;
      color: var(--color-white);
      font-size: 1.2em;
      margin-bottom: 15px;
    }
  }
}