.c-promo-feature-hero {
  background: var(--background-layer-tetriary);
  display: flex;
  justify-content: center;

  &__screen {
    max-height: 698px;
    height: calc(100vh - 68px - 40px - 16px - 358px - 48px - 48px);
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 48px;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      padding: 24px;
      width: 24%;
      background-color: var(--background-layer-quaternary);
      border: 1px solid var(--border-color-main);
      border-radius: 4px;
      line-height: 1.5;

      .c-icon {
        fill: var(--color-brand);
        stroke: var(--color-brand);
        width: 48px;
      }

      &__title {
        font-size: 22px;
        font-weight: 700;
        height: 66px;
        margin-bottom: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .c-promo-feature-hero__list__item {
    width: 48%;
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .c-promo-feature-hero{
    &__screen{
      display: none;
    }
  
    &__container{
      padding: 25px;
      max-width: 1200px;
    }
  
    &__list {
      display: flex;
      flex-direction: column;
    }
  
    &__list__item {
      display: grid;
      grid-template-columns: 65px auto; 
      width: 100%;
      border-radius: 5%;
    }
  
    &__list__item__title{
      max-width: 210px;
    }
  
    &__list__item__description{
      grid-column: 1/span 2;
    }
  }
}