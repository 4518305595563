.c-priority-select {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
  }

  &__slider {
    display: flex;
    align-items: flex-end;
    width: 100%;
    flex-direction: column;

    input[type="range"] {
      -webkit-appearance: none;
      width: 100%;
      height: 4px;
      cursor: pointer;
      background: var(--background-icon-color-gray);
      border-radius: 2px;
      z-index: 1;
    }
    
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
      cursor: pointer;
      border-radius: 50%;
      background: var(--background-icon-color-blue);
    }
    
    input[type=range]::-webkit-slider-runnable-track  {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    .c-priority-select__circles {
      display: flex;
      justify-content: space-between;
      width: 100%;
      position: relative;
      bottom: 12px;
      right: 2px;
    }

    .c-priority-select__circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: var(--background-icon-color-gray);
    }
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: var(--text-color-tetriary);
    margin-top: -8px;
    z-index: 2;
  }
}
