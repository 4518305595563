.c-task-date {
  margin-bottom: 16px;

  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    position: relative;

    &:first-child {
      margin-bottom: 16px;
    }

    &__input {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 0;

      input[type="date"] {
        border: none;
        background-color: inherit;
        color: var(--text-color-additional);
        font-size: 16px;
        outline: none;
        font-family: var(--main-font);
        padding: 0px;

        &::-webkit-calendar-picker-indicator {
          opacity: 0;
          z-index: 503;
          width: 1.8em;
          height: 1.8em;
          padding: 0px;
          position: relative;
          right: -3px;
          cursor: pointer;
        }

        &:not(.c-task-date__item__input__has-value)::placeholder {
          color: transparent;
        }

        &:not(.c-task-date__item__input__has-value)::-webkit-datetime-edit-text,
        &:not(.c-task-date__item__input__has-value)::-webkit-datetime-edit-month-field,
        &:not(.c-task-date__item__input__has-value)::-webkit-datetime-edit-day-field,
        &:not(.c-task-date__item__input__has-value)::-webkit-datetime-edit-year-field {
          color: transparent;
        }
      }

      &__placeholder {
        position: absolute;
        left: 0;
        color: var(--text-color-additional);
        font-size: 16px;
        padding: 0px;
      }
    }

    .c-icon-calendar {
      fill: var(--background-icon-color-blue);
      right: 1px;
      position: absolute;
      z-index: 502;
    }
  }

  &.mobile {
    .c-task-date__item {
      input[type="date"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }
  }
}