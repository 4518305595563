.c-user-time-report {

  &__header {
    color: var(--text-color-quaternary);
    font-weight: 500;
    margin-bottom: 12px;
  }

  &__list {
    margin-bottom: 8px;
  }

  &__list__item {
    color: var(--text-color-tetriary);
    padding: 4px 0px;

    .c-user-time-report__list__item-data {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child:hover {
        color: var(--text-color-quaternary);
      }
    }
  }

  &__total-time {
    display: grid;
    grid-template-columns: 20%;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
    font-size: 16px;
  }

  .c-user-time-report__header,.c-user-time-report__list__item {
    display: grid;
    grid-template-columns: 20% 40% 20% 20%;
    align-items: center;
  }
}
