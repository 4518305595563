.c-error-layout {
  min-height: 100vh;
  min-width: 360px;
  width: 100%;

  &__content {
    min-height: auto;
    background: var(--background-layer-primary);
  }

  [class^="v-"] {
    width: 100%;
    min-height: calc(100vh - var(--header-height)  -  var(--footer-height));
    padding: 24px;
  }
}

@media screen and (max-width: 1360px) {
  .c-error-layout {
    [class^="v-"] { 
      min-height: calc(100vh - var(--header-height)  -  var(--footer-height));
    }
  }
}

@media screen and (max-width: 768px) {
  .c-error-layout {
    [class^="v-"] { 
      min-height: calc(100vh - var(--header-height)  -  var(--footer-height));
      padding: 16px;
    }
  }
}