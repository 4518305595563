.c-color-palette {
  display: flex;
  flex-wrap: wrap;
  width: 104px;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--background-layer-secondary);
  box-shadow: var(--border-color-main) 0px 0px 0px inset, var(--border-color-main) 0px 0px 0px 1px inset;

  &__color_selected {
    box-shadow: var(--color-graphite-light) 0px 0px 0px, var(--color-graphite-light) 0px 0px 0px 2px;
  }

  &__color {
    border: none;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 4px;
    cursor: pointer;

    &:hover {
      box-shadow: var(--color-aqua) 0px 0px 0px, var(--color-aqua) 0px 0px 0px 2px;
    }
  }
}
