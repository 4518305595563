.c-promo-feature-list {
  background: var(--background-layer-tetriary);
  display: flex;
  justify-content: center;

  &__title {
    font-size: 60px;
    margin-bottom: 48px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      width: 45%;
      margin-bottom: 48px;

      &__number {
        color: var(--color-brand);
        font-size: 64px;
        font-weight: 700;
        margin-bottom: 8px;
      }

      &__title {
        height: 108px;
        font-size: 36px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
      }

      &__description {
        line-height: 1.5;
        color: var(--text-color-tetriary);
      }

      .c-button {
        margin-top: 24px;
      }
      .c-promo-feature-list__list__item__button__invisible{
        display: none;
      }
    }
  }
}

@media (max-width:768px){
  .c-promo-feature-list{
    &__list__item__title {
      display: flex;
      align-items: center;
      line-height: 120%;
      font-size: min(5vw);
      font-weight: 600;
      max-width: 100%;
      padding-top: 10px;
    }

    &__list__item__number{
      font-size: 75px;
    }
  
    &__title{
      display: flex;
      flex-direction: column;
      font-size: 8vw;
      font-weight: 700;
      line-height: 10vw;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .c-promo__logo{
      padding: 0;
    }
  
    &__list__item{
      display: grid;
      grid-template-columns: 95px auto;
      width: 100%;
    }
  
    &__list__item__description{
      grid-column: 1/span 2;
    }

    &__list__item__button__mobile__invisible{
      display: none;
    }

    .c-promo-feature-list__list__item__button__invisible{
      display: contents;
      .c-button{
          width: 91vw;
      }
    }
  }
}